import { Injectable, signal } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from '../services/user-data.service';
import { SidebarModel, DialogType } from 'src/app/shared/enums/DeploySideBarOptions';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeployDialogComponent } from 'src/app/shared/dialogs/deploy-dialog/deploy-dialog.component'
import { DeployerrorDialogComponent } from 'src/app/shared/dialogs/deployerror-dialog/deployerror-dialog.component'

@Injectable({
  providedIn: 'root'
})

export class DeployService {


  public result: SidebarModel[] = [];
  public mySideMenu: SidebarModel[] = [];
  public deployMenu: SidebarModel;
  public detectMenu: SidebarModel;
  public portainerMenu: SidebarModel;

  public checkboxForm: UntypedFormGroup;

  public idPlanta: number;
  public backendApi: JSON[];
  public deployParams: JSON[];
  public dadosPanteraPlanta;
  public pcsCCO: JSON[];
  public panteraDetect: JSON[];
  public portainerData: JSON[];
  public jsonLoaded = false;
  public selectedDialogType: DialogType;

  public nomePlanta;
  public nomeCliente;


  public valorDesejado: string;
  public dataTable;
  public tableType;
  public loaded = false;
  public dataLoaded = '';
  public arrayChbx;
  public pullCounter = 0;
  public pullComplete = false;
  public runComplete = false;
  public runCounter = 0;
  public portainerImages = {};
  public keepCalling: boolean;
  public timerPortainer;
  public endpointContainers: {[key: number]: any } = {};
  private isP2Wanted: boolean;

  public modules = {'frontend': false, 'backend': false, 'detection': false, 'model': false, 'model2': false, 'webrtc': false};
  public modulesImages = {'frontend': false, 'backend': false, 'detection': false, 'model': false, 'model2': false, 'webrtc': false};
  public modulesKeys: string[];

  public deployParamsData = signal({});


  public endpointStatus ={};
  
  DialogComponents = {
    [DialogType.Deploy]: DeployDialogComponent,
    [DialogType.DeployError]: DeployerrorDialogComponent,
  };

  constructor(
    private http: HttpService,
    private user: UserDataService,
    private dialog: MatDialog,

  ) { }

  // _____________________________ DATA TO LOAD PAGE _________________________________//

  async saveInfos(pantera_plantas){
    this.idPlanta = this.user.getIdPlanta();
    this.backendApi = await this.http.maestroGet(`get_backend_api/${this.idPlanta}`);
    this.deployParams = await this.http.maestroGet(`get_deploy_params/${this.idPlanta}`);
    this.pcsCCO = await this.http.maestroGet(`get_pcs_c00_planta/${this.idPlanta}`);
    this.panteraDetect = await this.http.maestroGet(`get_pantera_detect_planta/${this.idPlanta}`);
    this.portainerData = await this.http.maestroGet(`get_portainer_data/${this.idPlanta}`);
    const plantaSelected = pantera_plantas.filteredData.filter((x) => x.id_planta === this.idPlanta);
    this.nomeCliente = plantaSelected[0]['nome_cliente'];
    this.nomePlanta = plantaSelected[0]['nome_planta'];
    this.dadosPanteraPlanta = await this.http.maestroGet(`dados_pantera_planta/${this.idPlanta}`);

    this.checkIfP2Wanted();
    this.modulesKeys =  Object.keys(this.modules);
    this.jsonsProcessingFunc();
    this.result = await this.createSideMenu();
    this.deployParamsData.set(this.selectParamsDeploy());
  }


  jsonsProcessingFunc(){
    this.panteraDetect['cloud_deploy'] = JSON.stringify(this.panteraDetect['cloud_deploy'], null, 2);
  }


  // _____________________________ CREATE MENU FUNCS _________________________________//

  async createSideMenu(){
    this.deployMenu = this.createMenu('Deploy parameters', false, this.deployParams, []);
    this.detectMenu = this.createMenu('Pantera Detect', true, this.panteraDetect, []);
    
    if (this.portainerData) { this.detectMenu = this.addDeployOptionsMenu(this.detectMenu); }
    if (this.portainerData) { this.portainerMenu = this.createMenu('Portainer', false, this.portainerData, this.portainerData.map(x => ({childtext: x['id_endpoint'], link: x['id_planta'], data: x})));}
    this.mySideMenu = [ this.detectMenu];
    if (this.portainerData) {this.mySideMenu.push.apply(this.portainerMenu);}
    this.loaded = true;

    return this.mySideMenu;
  }


 // _____________________________ AUXILIARY FUNCS IMPORTANTES _________________________________//

 getVersion(stack){
  switch (stack) {
    case 'frontend':
      if (this.isP2Wanted) {return this.panteraDetect['frontend_version'];}
      return this.panteraDetect['wanted_pantera_version'];
    case 'webrtc':
      if (this.isP2Wanted) {return 'HTTPP2';} 
      return 'HTTP';
    default:
      if (stack.includes('model')) {return this.panteraDetect['id_modelo'];} 
      return this.panteraDetect['wanted_pantera_version'];
  }
}

checkIfP2Wanted(){
  this.isP2Wanted = parseInt(this.panteraDetect['wanted_pantera_version'][0], 10) >= 2;
  if (this.isP2Wanted){
    Object.assign(this.modules, { 'couchdb': false });
    Object.assign(this.modulesImages, { 'couchdb': false });
    delete this.modules.webrtc
    delete this.modulesImages.webrtc
    return
  }
}

 // _____________________________ PULL IMAGES FUNCS _________________________________//

  async pullWantedVersion(){
    this.selectedDialogType = DialogType.Deploy;
    this.dialog.open(this.DialogComponents[this.selectedDialogType],{'data': {'action': "Download de imagens", 'dialog': ''}});
    const computadores = this.dataTable;
    this.pullComplete = false;
    computadores.forEach(element => {
      const endpoint = element.id_endpoint;
      const filteredStacks = element.stacks.filter((x) => this.modules[x]);
      filteredStacks.forEach(async stack => {
        this.selectStackToPull(stack, endpoint);
      });
    });
  }

  selectStackToPull(stack, endpoint){
    if (stack !== 'backend'){
      let dataReq = {
        endpoint,
        'stack': stack,
        'versao': this.getVersion(stack)
      };
      this.pullImage(dataReq);
      return
    }
    this.pullCounter = this.pullCounter - 2;
    ['backend', 'cameraslogs', 'socketio'].forEach(async element => {
      let dataReq = {
        endpoint,
        'stack': element,
        'versao': this.getVersion(stack)
      };
      this.pullImage(dataReq);
    });
    }

  async pullImage(dataReq){
    let retries = 0;
    const maxRetries = 3;
    const retryDelay = 5000;
    let httpServ = this.http;
    async function doPull() {
      if (retries < maxRetries) {
        httpServ.maestroPost('pull_images_docker_portainer', dataReq)
          .then(data => {
              if (data === true){console.log('Pull successful', data);}
              if (data === false){
                console.error('Pull failed with error', data);
                retries++;
                setTimeout(doPull, retryDelay);
              }
          })
      } else {
        console.error('Max retries reached');
        this.selectedDialogType = DialogType.DeployError;
        this.dialog.open(this.DialogComponents[this.selectedDialogType],{'data':{'action': `Pull ${dataReq.stack}` , 'dialog': 'portainerError'}});
      }
    }
    doPull();
  }


  // _____________________________ DEPLOY FUNCS _________________________________//

  async deployWantedVersion(){
    const computadores = this.dataTable;
    const stacksToRemove = this.modulesKeys.filter(x => this.modules[x]);
    this.runComplete = false;
    // TODO - ANTES DE ATUALIZAR O MODELO DEVE-SE GARANTIR QUE O MESMO ESTEJA INSTALADO
    if (stacksToRemove.includes('model')) {
      await this.http.maestroGet(`update_installed_model/${this.idPlanta}/${this.panteraDetect['id_modelo']}`);
    }
    const parametrosDeploy = this.selectParamsDeploy();

    this.selectedDialogType = DialogType.Deploy;
    this.dialog.open(this.DialogComponents[this.selectedDialogType],{'data': {'action': "Deploy do pantera", 'dialog': 'pressedButtom'}});
    
    computadores.forEach(async element => {
      if (element.stacks == null) { this.runCounter++; return; }
      const dataReq = {
        user: this.user.getUsername(),
        idPlanta: this.idPlanta,
        stacks: element.stacks.filter((x) => this.modules[x]),
        endpoint: element.id_endpoint,
        deploy_params: parametrosDeploy // O maestro poderia fornecer este dado diretamente
      };
      let rota = 'deploy_portainer_lan';
      if (this.isP2Wanted) {rota += '_p2';}
      this.http.maestroPost(rota, dataReq);
    });
  }

  selectParamsDeploy(){
    if (this.isP2Wanted) { 
      return this.deployParams;
    } else {
      return this.createLanParams()
    }
  }

  // _____ P1 DEPLOY PARAMS _______//
  createLanParams(){
    const cloudDeploy  = JSON.parse(this.panteraDetect['cloud_deploy']);
    return {
      wanted_pantera_version: this.panteraDetect['wanted_pantera_version'],
      backend_api: this.panteraDetect['backend_api'],
      fuso_horario: this.deployParams['fuso_horario'],
      id_modelo: this.panteraDetect['id_modelo'],
      streaming_DNS: this.panteraDetect['streaming_type'] === 'cloud-ws' ? cloudDeploy['streaming_DNS'] : 'N/A',
      streaming_type: this.panteraDetect['streaming_type']
    };
  }


  // _____________________________ CHECK IF IMAGES EXISTS FUNCS _________________________________//
  async consultImagesPortainer(){
    this.timerPortainer = setTimeout(function () {
      if (this.keepCalling){
        if (this.portainerData){
          const promises = this.portainerData.forEach(async element => {
            let idEndpoint = element.id_endpoint;
            const data = await this.http.maestroGet(`get_portainer_images_containers/${element.id_endpoint}`, '', true);
            Object.assign(this.endpointStatus, { [idEndpoint]: data? true : false });
            Object.assign(this.endpointContainers, { [idEndpoint]: data? data.containers : false });
            if (data){
              let data2 = data.imagens.map((element) => {
                return element.RepoTags ? element.RepoTags[0] : '' }
                );
              data2 = data2.filter((element) =>  element !== undefined);
              element.stacks.forEach( async element => {
                this.checkIfImgTagExists(data2, element);
              }); 
            }
          });
        }
      };
      this.consultImagesPortainer();
    }.bind(this), 20000);
  }

  checkIfImgTagExists(data2, element){
    switch (true) {
      case element.startsWith('model'):
        let found = data2.some(word => word.includes(`pantera-model:${this.getVersion(element)}`));
        this.modulesImages['model'] = found;
        this.modulesImages['model2'] = found;
        break;
      default:
        this.modulesImages[element] = data2.some(word => word.includes(`${element}:${this.getVersion(element)}`));
        break;
    }
  }



    // _____________________________ DIALOGS _________________________________//

    showDialog(data: object, dialogType: DialogType) {
      this.dialog.open(this.DialogComponents[dialogType], data);
    }


    // _____________________________ PORTAINER COMMAND DEPLOY PORTAINER AGENT _________________________________//
    createDockerCmdPortainer(edge_id: string, edge_key:string) {
      return `docker rm -f portainer_edge_agent ;
      docker run -d \`
        -v /var/run/docker.sock:/var/run/docker.sock \`
        -v /var/lib/docker/volumes:/var/lib/docker/volumes \`
        -v /:/host \`
        -v portainer_agent_data:/data \`
        --restart always \`
        -e EDGE=1 \`
        -e EDGE_ID=${edge_id} \`
        -e EDGE_KEY=${edge_key} \`
        -e CAP_HOST_MANAGEMENT=1 \`
        -e EDGE_INSECURE_POLL=1 \`
        --name portainer_edge_agent \`
        portainer/agent:2.19.1`
    }
  

 // _____________________________ AUXILIARY FUNCS NIGM LIGA _________________________________//
    createMenu(name, is_menu, data, submenu): SidebarModel {
      const result =   {
          linkText: name,
          parentLink: '',
          menu: is_menu,
          data: data,
          submenu: submenu
        };
      return result;
    }
  
  
    // FUNÇÔES AUXILIARES PAR ESTRUTURAR A PAGINA
    getHeaders() {
      const headers: string[] = [];
      if (this.dataTable) {
        this.dataTable.forEach((value) => {
          Object.keys(value).forEach((key) => {
            if (!headers.find((header) => header === key)){
              if (['presets_day', 'presets_night'].indexOf(key) === -1) {
                headers.push(key);
              }
            }
          });
        });
      }
      return headers;
    }
  
    addDeployOptionsMenu(deployMenu){
      let remoteDeployData = this.portainerData ? this.portainerData.map(x => Object.assign(
        {}, { id_instalacao: x['id_instalacao'], id_endpoint: x['id_endpoint'], pc_name: this.getPCName(x['id_instalacao']), stacks: x['stacks']  }
        )) : 'undefined';
        
      deployMenu.submenu = [
        {childtext: 'Deploy Remoto', link: 'Fazer deploy portainer', data: remoteDeployData },
      ];
      return deployMenu;
    }
  
    getPCName(idInstalacao){
      const nomePC = this.pcsCCO.filter( (elem) =>  elem['id_instalacao'] === idInstalacao );
      return nomePC[0] ? nomePC[0]['nome_instalacao'] : 'Pantera Cloud';
    }
  
    getPCName2(idEndpoint){
      const PC = this.dataTable.filter( (elem) =>  elem.id_endpoint == idEndpoint );
      const nomePC = PC[0].pc_name;
      return nomePC
    }
 

}
