import { Component, OnInit, ViewChild, signal, computed, effect } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { HttpService } from '../../services/http.service'
import { UserDataService } from '../../services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DeployComponent } from 'src/app/components/deploy/deploy.component';
import {environment} from 'src/environments/environment'
import { LoggingService } from '../../services/logging.service';
import { SocketService } from 'src/app/services/socket.service';
import { TranslateService } from '@ngx-translate/core';

const CLIENT_COLUMN = 'nome_cliente'
const PLANTA_COLUMN = 'planta'
const PANTERA_VERSION_COLUMN = 'pantera_version'
const WANTED_PANTERA_VERSION_COLUMN = 'wanted_pantera_version'
const INSTALLED_FRONTEND_COLUMN = 'installed_frontend'
const WANTED_FRONTEND_COLUMN = 'wanted_frontend'
const INSTALLED_MODEL_COLUMN = 'installed_model'
const WANTED_MODEL_COLUMN = 'wanted_model'

@Component({
  providers:[DeployComponent ],
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit{
  @ViewChild(DeployComponent) componentDeploy: DeployComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    public user: UserDataService,
    private dialog: MatDialog,
    public logging: LoggingService,
    public socket: SocketService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    effect(() => {
      this.configurePanteraDetectTableSorting()
    })
  }

  @ViewChild('deployPlantasSort') deployPlantasSort = new MatSort();

  public envDev = !environment.production

  public loaded: boolean = false;
  public loadingNewUser: boolean = false;
  public roles: any[];

  public plantas = signal([]);
  public pantera_plantas = computed(() => {
    const plantasWithWantedPanteraVersion = this.plantas().filter(p => p.wanted_pantera_version != null)
    const dataSource = new MatTableDataSource(plantasWithWantedPanteraVersion)
    return dataSource
  });
  public plantasDataSource = computed(() => {
    const dataSource = new MatTableDataSource(this.plantas())
    return dataSource
  })

  private stringify_pantera_features = {}
  public users_planta: any[];


  public leadsStartDate: Date;
  public leadsEndDate: Date;

  public versions: { id_version: number, version: string }[] = [];
  public frontend_versions = [];
  public models = [];

  public cameras: any[];
  public camTableColumns: string[] = [
    'id_instalacao',
    'nome_local',
    'ip_lan',
    'ip_wan',
    'use_ip_wan',
    'use_cam_rtsp',
    'rtsp_url',
    'model_host',
    'model_port',
  ];


  public panteraTableColumns: string[] = [
    CLIENT_COLUMN,
    PLANTA_COLUMN,
    PANTERA_VERSION_COLUMN,
    WANTED_PANTERA_VERSION_COLUMN,
    INSTALLED_FRONTEND_COLUMN,
    WANTED_FRONTEND_COLUMN,
    INSTALLED_MODEL_COLUMN,
    WANTED_MODEL_COLUMN,
    'updated_dt',
    'backend_api',
    'threshold',
    'threshold_zoom',
    'save_img_fp_percent',
  ];

  public plantasTableColumns: string[] = [
    'nome_cliente',
    'planta',
    'cam_detection',
    'fire_mngmt',
    'satellite',
    'risk_map',
    'brigades_position',
    'scars',
    'propagation',
    'deforestation'
  ];

  public rolesTableColumns: string[] = [
    'id_role',
    'user_role',
    'role_description',
  ];


  newUserForm = this.fb.group({
    id_planta: ['',[Validators.required]],
    id_role: ['',[Validators.required]],
    username: ['',[Validators.required]],
    email: ['',[Validators.required, Validators.email]],
    nome: [''],
    sobrenome: ['']
    });


  async ngOnInit() {
    this.loaded = false;

    await this.updatePlantas();

    let admin_data = await this.http.maestroGet('get_admin_data');
    console.log('admin_data', admin_data);

    this.versions = admin_data['versions'] || [];
    this.frontend_versions = admin_data['frontend_versions'] || [];
    this.sortVersions(this.frontend_versions);
    this.sortVersions(this.versions);

    this.models = admin_data['models'] || [];

    this.roles = admin_data['user_roles'] || [];
    this.roles.sort((r1, r2) => r1.id_role - r2.id_role);

    this.cameras = await this.http.maestroGet(`get_cameras_planta/${this.user.getIdPlanta()}`) || [];
    this.cameras.sort((c1, c2) => c1.id - c2.id)
    console.log('%c🗼 CamList', 'background: #222; color: #bada55', this.cameras);

    this.users_planta = await this.http.maestroGet(`get_all_users/${this.user.getIdPlanta()}`);

    this.loaded = true;

    this.socket.joinIdPlantRoom()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          this.socket.disconnectFromSockCent();
        }
    });
  }

  //Remover quando dropar deploy old

  // onTabChanged(evento){
  //   this.componentDeploy.changeKeepCalling(evento.index)
  //   if (evento.index === 5) {
  //     console.log('DEPLOY ANTIGO VAI RODAR')
  //     this.componentDeploy.deployService.saveInfos(this.pantera_plantas());
  //   }

  // }


  sortVersions(versionList){
    versionList.sort((v1, v2) => {
      const parsedV1 = this.parseVersion(v1.version);
      const parsedV2 = this.parseVersion(v2.version);

      for (let i = 0; i < Math.max(parsedV1.length, parsedV2.length); i++) {
        const part1 = parsedV1[i] || 0;
        const part2 = parsedV2[i] || 0;
        if (part1 !== part2) {
          return part2 - part1;
        }
      }
      return 0;
    });
  }

  configurePanteraDetectTableSorting(){
    this.pantera_plantas().sort = this.deployPlantasSort;

    this.pantera_plantas().sortingDataAccessor = (item, property) => {
      switch (property) {
        case CLIENT_COLUMN:
          return item.nome_cliente;
        case PLANTA_COLUMN:
          return item.nome_planta;
        case PANTERA_VERSION_COLUMN:
        case WANTED_PANTERA_VERSION_COLUMN:
        case INSTALLED_FRONTEND_COLUMN:
          return this.parseVersion(item[property]);
        case WANTED_FRONTEND_COLUMN:
          return this.parseVersion(item.frontend_version);
        case INSTALLED_MODEL_COLUMN:
          return item.installed_model;
        case WANTED_MODEL_COLUMN:
          return item.id_modelo;
        default:
          return item[property];
      };
    }
    console.log('pantera_plantas:', this.pantera_plantas().data)
  }

  parseVersion(version: string | null | undefined): number[] {
    if (!version) {
      return [0];
    }
    // Remove any suffix (like "-b") using regex
    const cleanedVersion = version.replace(/-[a-zA-Z]+$/, '');
    // Split the cleaned version string by dots and convert to numbers
    return cleanedVersion.split('.').map(part => parseInt(part, 10) || 0);
  }

  sortPanteraPlantasData(){
    this.configurePanteraDetectTableSorting()
  }

  async updatePlantas(){
    const plantas = await this.http.maestroGet('get_plantas');
    this.plantas.set(plantas)

    this.plantas().forEach(p=>{
      this.stringify_pantera_features[p.id_planta] = JSON.stringify(p.pantera_features)
    })
  }

  async newUserSubmit(){

    this.loadingNewUser = true;

    let newUser = {
      username : this.newUserForm.value.username,
      id_planta : this.newUserForm.value.id_planta,
      id_role : this.newUserForm.value.id_role,
      email : this.newUserForm.value.email,
      nome : this.newUserForm.value.nome,
      sobrenome : this.newUserForm.value.sobrenome
    }

    console.log('newUser', newUser)

    let response = await this.http.maestroPost('new_user', newUser)
    console.log('new_user_response', response);

    this.loadingNewUser = false;

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.USER_CREATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }

    this.newUserForm.reset()

  }


  async updateConfigCameras(){
    console.log('updateConfigCameras', this.cameras)
    let response = await this.http.maestroPost('update_config_cameras_pweb', this.cameras)
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.CAMERAS_UPDATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }
  }

  changedFeatures(element){
    return JSON.stringify(element.pantera_features) != this.stringify_pantera_features[element.id_planta]
  }

  async sendChanges(element){

    let obj = {
      'id_planta': element.id_planta,
      'pantera_features': element.pantera_features
    }

    console.log('update_pantera_features', obj)
    let response = await this.http.maestroPost('update_pantera_features', obj)

    this.updatePlantas();

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.PLANT_FEATURES")} ${element.id_planta}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")}: ${response?.msg}`}
      });
    }

  }

  changedElement(event, element){
    element.changed = true;
  }

  async updateConfigs(planta){
    console.log(planta)
    let obj = {
      'id_planta': planta.id_planta,
      'wanted_pantera_version': planta.wanted_pantera_version,
      'id_modelo': planta.id_modelo,
      'd_model_tsh': planta.model_tsh,
      'smoke_tsh_zoom': planta.smoke_tsh_zoom,
      'frontend_version': planta.frontend_version,
    }

    console.log('update_pantera_configs', obj);
    let response = await this.http.maestroPost('v2/update_pantera_detect', obj);

    this.updatePlantas();

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.SETTINGS_UPDATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }

  }

  async installWantedVersion(data){
    let id_planta = data.id_planta;
    let response = await this.http.maestroGet(`delete_containers/${id_planta}`);
    response = await this.http.maestroGet(`deploy_pantera/${id_planta}`);
    data.pantera_version = data.wanted_pantera_version
    return true
  }

  getStreamingIcon(tipo){
    let dict = {
      'docker-ws':'🐋',
      'go-webrtc':'go',
    }
    return dict[tipo]
  }

}
