import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';

import { ReportsService } from '../../services/reports.service';
import { GeoService } from '../../services/geo.service';
import { UserDataService } from '../../services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { FilterService } from 'src/app/services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { PropagationService } from 'src/app/services/propagation.service';

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit, OnDestroy {

  constructor(
    public reports:ReportsService,
    public geo:GeoService,
    public user: UserDataService,
    private amplitude: AmplitudeService,
    public filterService: FilterService,
    public translate: TranslateService,
    private prop: PropagationService
    
  ) {}
  public searchText: string;

  public reportTableColumns=[
    'n_relatorio_planta',
    'data_deteccao',
    'resultado_modelo',
    'nome_local',
    'dist_local',
    // 'dist_torre',
  ]

  filters= [
    { label: this.translate.instant("MAP.ONGOING_REPORT"), prop: 'filterInProgress' },
    { label: this.translate.instant("MAP.HAS_RISK"), prop: 'filterHasRisk' },
    { label: this.translate.instant("MAP.TRIGGERINGS"), prop: 'filterHasTriggerings' }
  ]

  ngOnInit(): void {}

  ngOnDestroy(): void {}


  async onEndDateChange(endDate: Date) {
    if (endDate) {
      this.filterService.updateFilters();
      this.amplitude.sendDatePickerEvent('reports', 'REP.REPORTS', `[${formatDate(this.reports.startDate, 'dd-MMM-yyyy', 'en-US')}, ${formatDate(this.reports.endDate, 'dd-MMM-yyyy', 'en-US')}]`);
    }
  }

  activateFilter() {
    this.filterService.updateFilters()
  }

  get currentLocale(): string {
    return this.translate.currentLang === 'pt-br' ? 'pt-BR' : 'en-US';
  }

  async selectReport(idReport: string){
    this.geo.activateLayer(3, 'MAP.REPORTS');
    
    this.reports.selectReport(idReport);
    this.reports.setRecentReports(this.reports.reports_list.filter(r=> r.recent).length);

    this.geo.updateReportSatDetectionGroup();
    await this.geo.updateReportSatImageGroup(idReport);
    await this.geo.updateReportScarGroup(idReport);

    if(this.reports.selectedR?.propagation){
      this.prop.createPropagationFromDB();
    }
    this.geo.openReportMarker(idReport);
    this.amplitude.sendEvent('Abriu Relatório', {'Origem': 'Tabela', 'Número Relatório': this.reports.selectedR["n_relatorio_planta"], 'ID Relatório': idReport});    
  }
}
