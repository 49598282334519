import { Injectable } from '@angular/core';
import { GuidedTourService } from 'ngx-guided-tour';
import { AmplitudeService } from '../services/amplitude.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

const layersOrient = [{
  orientationDirection: 'bottom',
  maximumSize: 600
},
  {
    orientationDirection: 'right',
    maximumSize: 3000
}]

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private guidedTourService: GuidedTourService,
    public translate: TranslateService,
    private amplitude: AmplitudeService
  ) { }

  private welcomeTour;
  private mapTour;
  public loadedSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private introVideoClickListenerAdded = false;

  public setLoaded(loaded: boolean): void {
    this.loadedSub.next(loaded);
  }

  startMapTour() {
    let pt=this.translate.currentLang == 'pt-br'

    let steps_title_content_PT = {
      'Bem-vindo(a) ao Pantera!': `<div><a href="https://www.loom.com/share/7ae48c8c738e46a9bc2d1a02cc4d95b1" target="_blank"><img class="pweb-video-thumbnail" src="https://cdn.loom.com/sessions/thumbnails/1c9a4684ee3d40ac83d84216704a2bbf-with-play.gif"></a></div><div>Versão ${environment.version}</div>`,
      'Funcionalidades': '<li>Módulos do Pantera</li><li>Painéis interativos para gestão de incêndios</li>',
      'Camadas do mapa':'<li>Dados espaciais temáticos</li>',
      'Relatórios': '<li>Consulte e edite relatórios de detecções confirmadas</li><li>Simule a propagação do incêndio</li><li>Vizualize dados graficamente e exporte em formato de planilha</li><li>Cadastre destinatários de relatórios (e-mail)</li>',
      'Câmeras': '<li>Assista ao videomonitoramento em tempo real</li><li>Verifique os parâmetros das imagens base (presets)</li><li>Entre nos grupos Telegram para recebimento de alertas e relatórios</li>',
      'Detecção Satelital': '<li>Visualize dados de detecções ativas</li><li>Crie relatórios a partir dessas detecções</li><li>Cadastre destinatários de alerta (e-mail, WhatsApp e grupo Telegram)</li><li>Exporte as detecções históricas</li>',
      'Recursos':'<li>Consulte e cadastre: brigadas, pontos de interesse e contatos</li>',
      'Meteorologia':'<li>Consulte dados meteorológicos e o índice de risco de incêndio</li><li>Cadastre destinatários do informativo de risco diário (e-mail)</li>',
      'Dados Históricos':'<li>Consulte dados de área queimada e emissões de CO2</li><li>Consulte dados de desmatamento</li>',
      'Camadas base':'<li>Mapa de fundo</li><li>Área monitorada</li><li>Unidades de Conservação</li><li>Terras Indígenas</li><li>Outros limites</li>',
      'Camadas de relatórios':'<li>Relatórios de detecções confirmadas</li>',
      'Camadas de câmeras':'<li>Infraestrutura de torres e central</li><li>Mapa de visibilidade</li>',
      'Camadas de detecção satelital':'<li>Focos de calor ativos e respectivos agrupamentos</li><li>Histórico de detecções</li><li>Órbita do satélite</li><li>Focos de calor na área expandida</li>',
      'Camadas de recursos': '<li>Rastreamento de frotas</li><li>Pontos de interesse</li>',
      'Camadas de risco de incêndio':'<li>Risco de incêndio hoje e nos próximos 3 dias</li><li>Material combustível</li><li>Direção do vento e precipitação</li>',
      'Camadas de dados históricos': '<li>Área queimada</li><li>Focos de calor do satélite de referência</li><li>Desmatamento</li>'
    }

    let layersOrientation = layersOrient;

    this.mapTour = {
      tourId: 'welcome-tour',
      useOrb: false,
      steps: [
        {
          title: pt?`${Object.keys(steps_title_content_PT)[0]}`:'Welcome to Pantera!',
          content: pt?`${Object.values(steps_title_content_PT)[0]}`:`<div><a href="https://www.loom.com/share/a115deb0fa5e4293b6310c273a380acf" target="_blank"><img class="pweb-video-thumbnail" src="https://cdn.loom.com/sessions/thumbnails/1c9a4684ee3d40ac83d84216704a2bbf-with-play.gif"></a></div><li>Version ${environment.version}</li>`,
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[1]}`:'Features',
          selector: '#features-nav',
          orientation: 'left',
          content: pt?`${Object.values(steps_title_content_PT)[1]}`:'<li>Software modules</li><li>Interactive panels for fire management</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[2]}`:'Map layers',
          selector: '#layers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[2]}`:'<li>Thematic spatial data</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[3]}`:'Fire Reports',
          selector: '#ocorrencias-btn',
          orientation: 'left',
          content: pt?`${Object.values(steps_title_content_PT)[3]}`:'<li>View and edit fire reports</li><li>Simulate fire spread</li><li>View dashboards and download data</li><li>Register recipients to receive reports (email)</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[4]}`:'Cameras',
          selector: '#cams-btn',
          orientation: 'left',
          content: pt?`${Object.values(steps_title_content_PT)[4]}`:'<li>Watch live camera images</li><li>Check camera presets</li><li>Access Telegram group for fire alerts</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[5]}`:'Satellite Detection',
          selector: '#satellites-btn',
          orientation:'left',
          content: pt?`${Object.values(steps_title_content_PT)[5]}`:'<li>Visualize active fire data</li><li>Create reports from detections</li><li>Register alert recipients (e-mail, WhatsApp and Telegram group)</li><li>Download historical data</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[6]}`:'Resources',
          selector: '#brigades-btn',
          orientation:'left',
          content: pt?`${Object.values(steps_title_content_PT)[6]}`:'<li>View and register: brigades, points of interest and contacts</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[7]}`:'Weather',
          selector: '#weather-btn',
          orientation:'left',
          content: pt?`${Object.values(steps_title_content_PT)[7]}`:'<li>View meteorological data and fire risk</li><li>Register email to receive daily risk information</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[8]}`:'Historical Data',
          selector: '#historical-data-btn',
          orientation:'left',
          content: pt?`${Object.values(steps_title_content_PT)[8]}`:'<li>View burned area data and CO2 emissions</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[9]}`:'Base layers',
          selector: '#baseLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[9]}`:'<li>Background map</li><li>Monitored area</li><li>Other limits</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[10]}`:'Report layers',
          selector: '#reportLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[10]}`:'<li>Fire reports</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[11]}`:'Visibility layers',
          selector: '#visibilityLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[11]}`:'<li>Infrastructure of towers and management center</li><li>Visibility map</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[12]}`:'Satellite layers',
          selector: '#satLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[12]}`:'<li>Active fire</li><li>Detection history</li><li>Orbit track</li><li>Active fire in expanded area</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[13]}`:'Resource layers',
          selector: '#fmLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[13]}`:'<li>Fleet tracking</li><li>Points of interest</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[14]}`:'Risk layers',
          selector: '#riskLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[14]}`:'<li>Fire risk today and from next 3 days</li><li>Fuel</li><li>Wind direction and precipitation</li>',
        },
        {
          title: pt?`${Object.keys(steps_title_content_PT)[15]}`:'Historical data layers',
          selector: '#historicalDataLayers-btn',
          orientation:layersOrientation,
          content: pt?`${Object.values(steps_title_content_PT)[15]}`:'<li>Burned area</li><li>Hotspots from a reference satellite</li>',
        },
      ],
      skipCallback: (stepSkippedOn: number) => this.skipOnboarding(stepSkippedOn),
      completeCallback: () => this.completeOnboarding()
    };
    this.guidedTourService.startTour(this.mapTour);
    this.observeIntroVideoClick();
  }

  observeIntroVideoClick(){
    const observer = new MutationObserver((mutations, obs) => {
      if (this.introVideoClickListenerAdded) {
        obs.disconnect();
        return;
      }
      const videoThumbnails = document.getElementsByClassName('pweb-video-thumbnail');
      if (videoThumbnails.length > 0) {
        const videoLink = videoThumbnails[0].parentElement;
        if (videoLink) {
          videoLink.addEventListener('click', this.trackIntroVideoClick.bind(this));
          this.introVideoClickListenerAdded = true;
          obs.disconnect()
        }
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  trackIntroVideoClick() {
    this.amplitude.sendEvent('Clicou Link', {'Destino': 'Loom', 'Assunto': 'Vídeo Introdução Pantera'})
  }

  skipOnboarding(stepSkippedOn:number) {
    this.introVideoClickListenerAdded = false
    for (let i=0; i < stepSkippedOn+1; i++){
      this.amplitude.sendEvent('Viu Etapa Onboarding', {'Etapa': i+1})
    }
    this.amplitude.sendEvent('Pulou Onboarding', {'Etapa': stepSkippedOn+1})
  }

  completeOnboarding(onboarding?:string){
    this.introVideoClickListenerAdded = false
    if (!onboarding){
      onboarding = 'Principal';
    }
    for (let i=0; i < this.mapTour['steps'].length; i++){
      this.amplitude.sendEvent('Viu Etapa Onboarding', {'Etapa': i+1})
    }
    this.amplitude.sendEvent('Completou Onboarding')
  }

}
