import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { RouteGuardsService } from './services/route-guards.service';
import { ScriptService } from './services/scripts.service';
import { UserDataService } from './services/user-data.service';
import { SafePipe } from './safe.pipe';

import { AppComponent } from './app.component';
import { MapComponent } from './pages/map/map.component';
import { CameraComponent } from './components/camera/camera.component';
import { StreamingComponent } from './components/streaming/streaming.component';
import { LoginComponent } from './pages/login/login.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { MeteoComponent } from './components/meteo/meteo.component';
import { SatelliteComponent } from './components/satellite/satellite.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ReportsComponent } from './reports-components/reports/reports.component';
import { ManagerComponent } from './components/manager/manager.component';
import { HotspotsComponent } from './components/hotspots/hotspots.component';
import { AdminComponent } from './pages/admin/admin.component';
import { BrigadesComponent } from './components/brigades/brigades.component';
import { LandTabsComponent } from './components/land-tabs/land-tabs.component';
import { PresetsComponent } from './components/presets/presets.component';
import { ReportImgsComponent } from './reports-components/report-imgs/report-imgs.component';
import { ReportsTableComponent } from './reports-components/reports-table/reports-table.component';
import { SatClusterComponent } from './components/sat-cluster/sat-cluster.component';
import { SatAlertsComponent } from './components/sat-alerts/sat-alerts.component';
import { FireDataComponent } from './components/fire-data/fire-data.component';
import { DangerComponent } from './components/danger/danger.component';
import { WeatherComponent } from './components/weather/weather.component';
import { SysstatusComponent } from './components/sysstatus/sysstatus.component';
import { MapCtrlComponent } from './components/map-ctrl/map-ctrl.component';
import { BioComponent } from './components/bio/bio.component';

import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';
import { UserTermsDialog } from 'src/app/shared/dialogs/user-terms-dialog/user-terms-dialog';
import { createReportDialog } from 'src/app/reports-components/reports-dialog/create-report-dialog';
import { ForgotPassDialog } from 'src/app/shared/dialogs/forgot-pass-dialog/forgot-pass-dialog';
import { AddImagesDialog } from 'src/app/shared/dialogs/add-img-dialog/add-img-dialog';
import { SelectBrigadeDialog } from 'src/app/reports-components/reports-dialog/select-brigade-dialog';
import { FullScreenDialog } from './shared/dialogs/cameras-dialog/cameras-dialog';
import { TelegramGroupsDialog } from './shared/dialogs/telegram-groups-dialog/telegram-groups-dialog';
import { createReportClusterDialog } from './components/sat-cluster/create-report-cluster';
import { GroupingSuggestionDialog } from './shared/dialogs/grouping-suggestion-dialog/grouping-suggestion-dialog';
import { ChooseReportToGroupDetectionDialog } from './shared/dialogs/choose-report-to-group-detection-dialog/choose-report-to-group-detection-dialog';
import { AutoPresetGeneratorDialog } from './shared/dialogs/auto-preset-generator/auto-preset-generator-dialog';
import { SimulatePresetsDialog } from './shared/dialogs/simulate-presets-dialog/simulate-presets-dialog';

import {
  SelectPerdasDialog,
  addTextDialog,
  LatLonDialog,
  PropagationDialog,
} from 'src/app/reports-components/reports-dialog/reports-dialog';
import { DownloadReportDialog } from 'src/app/reports-components/download-report/download-report-dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DrawMapComponent } from './components/draw-map/draw-map.component';
import { ClassifierLabelsComponent } from './components/classifier-labels/classifier-labels.component';
import { RiskAlertsComponent } from './components/risk-alerts/risk-alerts.component';
import { CreatePoiComponent } from './components/create-poi/create-poi.component';
import { PoiComponent } from './components/poi/poi.component';
import { StreamDetailComponent } from './components/stream-detail/stream-detail.component';
import { AlertGroupsComponent } from './components/alert-groups/alert-groups.component';
import { PropagationSimulationComponent } from './components/propagation-simulation/propagation-simulation.component';
import { CurrentSitComponent } from './components/current-sit/current-sit.component';
import { DeployComponent } from './components/deploy/deploy.component';
import { DeploySidebarComponent } from './components/deploy-sidebar/deploy-sidebar.component';
import { DeployDialogComponent } from './shared/dialogs/deploy-dialog/deploy-dialog.component';
import { DeployerrorDialogComponent } from './shared/dialogs/deployerror-dialog/deployerror-dialog.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CarbonComponent } from './components/carbon/carbon.component';
import { PropagationWidgetComponent } from './components/propagation-widget/propagation-widget.component';
import { HistoricalDataComponent } from './components/historical-data/historical-data.component';
import { DeforestationComponent } from './components/deforestation/deforestation.component';
import { ClientDashboardComponent } from './components/client-dashboard/client-dashboard.component';
import { MapCamerasControlsComponent } from './components/map-cameras-controls/map-cameras-controls.component';
import { CalibrationTabComponent } from './components/calibration-tab/calibration-tab.component';

import { SocketDisconnectedDialog } from './shared/dialogs/socket-disconnected/socket-disconnected.component';
import { LoadingAppComponent } from './shared/loading/loading..component';
import { FullScreenDashDialog } from './shared/dialogs/dashboard-dialog/full-screen-dash-dialog';
import { ClipboardModule } from 'ngx-clipboard';

import { SearchBrigadePipe, SearchPoiPipe, SearchReportPipe, SearchStationsPipe } from './services/filter-service.pipe';
import { CreateStationsComponent } from './components/create-station/create-stations.component';
import { MeteoStationsComponent } from './components/stations/meteo-stations.component';
import { DeployV2Component } from './components/deploy-v2/deploy-v2.component';
import { PortainerEndpointComponent } from './components/portainer-endpoint/portainer-endpoint.component';

import localePT from '@angular/common/locales/pt';
registerLocaleData(localePT);

// Integração Azure AD
// import {
//   IPublicClientApplication,
//   PublicClientApplication,
//   InteractionType,
//   BrowserCacheLocation,
//   LogLevel,
// } from '@azure/msal-browser';
// import {
//   MsalInterceptorConfiguration,
//   MsalService,
//   MSAL_INSTANCE,
//   MSAL_INTERCEPTOR_CONFIG,
//   MsalGuardConfiguration,
// } from '@azure/msal-angular';

// const isIE =
//   window.navigator.userAgent.indexOf('MSIE ') > -1 ||
//   window.navigator.userAgent.indexOf('Trident/') > -1;

// export function loggerCallback(logLevel: LogLevel, message: string) {
//   console.log(message);
// }

// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     // auth: {
//     //   // ricardo
//     //   clientId: '910e455b-a09f-4863-86ac-443ddd4e5bc0',
//     //   authority:
//     //     'https://login.microsoftonline.com/392ef21a-3686-4ce8-896a-74dc34621547',
//     //   redirectUri: environment.azureRedirectUri,
//     // },
//     auth: {
//       // Raizen
//       clientId: '29b475eb-49f3-4628-9fc6-9193ca4f08e9',
//       authority: 'https://login.microsoftonline.com/e7c411a6-9013-4967-a5b1-3d08f9edc13e',
//       redirectUri: environment.azureRedirectUri,
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage,
//       storeAuthStateInCookie: isIE, // set to true for IE 11
//     },
//     system: {
//       loggerOptions: {
//         loggerCallback,
//         logLevel: LogLevel.Info,
//         piiLoggingEnabled: false,
//       },
//     },
//   });
// }

// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
//     'user.read',
//   ]);

//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap,
//   };
// }

// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: {
//       scopes: ['user.read'],
//     },
//   };
// }
// Fim da integração Azure AD

// Debug ngx-tour-lib
declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Array<Provider | EnvironmentProviders>;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    CameraComponent,
    StreamingComponent,
    LoginComponent,
    LoaderComponent,
    HeaderComponent,
    MeteoComponent,
    CalibrationTabComponent,
    DashboardComponent,
    HotspotsComponent,
    SettingsComponent,
    SatelliteComponent,
    AdminComponent,
    ReportsComponent,
    ManagerComponent,
    PresetsComponent,
    ReportImgsComponent,
    ReportsTableComponent,
    BrigadesComponent,
    LandTabsComponent,
    MapCamerasControlsComponent,
    SatClusterComponent,
    FireDataComponent,
    DangerComponent,
    WeatherComponent,
    SatAlertsComponent,
    SysstatusComponent,
    MapCtrlComponent,
    AddImagesDialog,
    AutoPresetGeneratorDialog,
    ForgotPassDialog,
    InfoDialog,
    SimulatePresetsDialog,
    GroupingSuggestionDialog,
    ChooseReportToGroupDetectionDialog,
    UserTermsDialog,
    createReportDialog,
    createReportClusterDialog,
    SelectBrigadeDialog,
    FullScreenDialog,
    TelegramGroupsDialog,
    SelectPerdasDialog,
    addTextDialog,
    DownloadReportDialog,
    LatLonDialog,
    PropagationDialog,
    ConfirmationDialog,
    SafePipe,
    DrawMapComponent,
    ClassifierLabelsComponent,
    RiskAlertsComponent,
    CreatePoiComponent,
    PoiComponent,
    StreamDetailComponent,
    AlertGroupsComponent,
    PropagationSimulationComponent,
    CurrentSitComponent,
    SearchReportPipe,
    DeployComponent,
    DeploySidebarComponent,
    DeployDialogComponent,
    DeployerrorDialogComponent,
    ContactsComponent,
    CarbonComponent,
    BioComponent,
    PropagationWidgetComponent,
    HistoricalDataComponent,
    DeforestationComponent,
    SocketDisconnectedDialog,
    ClientDashboardComponent,
    LoadingAppComponent,
    FullScreenDashDialog,
    SearchBrigadePipe,
    SearchPoiPipe,
    CreateStationsComponent,
    MeteoStationsComponent,
    SearchStationsPipe,
    DeployV2Component,
    PortainerEndpointComponent
    
  ],
  imports: [
    ClipboardModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    GuidedTourModule,
    MatDialogModule,
    MatAutocompleteModule,
    DragDropModule,
    TranslateModule.forRoot({
      defaultLanguage: "pt-br",
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    RouteGuardsService,
    ScriptService,
    GuidedTourService,
    UserDataService,
    // Integração Azure login SSO
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MSALInstanceFactory,
    // },
    // {
    //   provide: MSAL_INTERCEPTOR_CONFIG,
    //   useFactory: MSALInterceptorConfigFactory,
    // },
    // MsalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}