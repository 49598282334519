import { Component, OnInit, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { SocketService } from 'src/app/services/socket.service';
import { ClipboardService } from 'ngx-clipboard';
import { DeployService } from 'src/app/services/deploy.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';

@Component({
  selector: 'app-deploy-sidebar',
  templateUrl: './deploy-sidebar.component.html',
  styleUrls: ['./deploy-sidebar.component.scss'],
})
export class DeploySidebarComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  expandAll = false;
  dialogRef: MatDialogRef<any> | null = null;
  constructor(
    private socket: SocketService,
    private clipboardService: ClipboardService,
    public deployService: DeployService,
    private dialog: MatDialog,
    ) { 
     }


  async ngOnInit(){

    this.socket.onFeedBackAttDadosEvent().subscribe(async (msg) => {
      const numClients = msg['numClientes'] - 1;
      const clientText = numClients === 1 ? 'computador respondeu' : 'computadores responderam';
      
      if (!this.dialogRef){
        this.dialogRef = this.dialog.open(InfoDialog, {
          data: {text: `Dados atualizados no cliente com sucesso! ${numClients} ${clientText}`},
        });
      }
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    });
  }



  async ngAfterViewInit(){
    this.deployService.consultImagesPortainer();

  }

  async ngOnChanges() {
    this.deployService.keepCalling = this.deployService.dataLoaded === 'Deploy Remoto' ? true : false;
  }

  ddToggle(i: number) {
    this.deployService.result[i].menu = !this.deployService.result[i].menu;
    this.deployService.dataTable = this.deployService.result[i].data;

    this.deployService.dataLoaded = this.deployService.result[i].linkText;
    if (this.deployService.result[i].submenu.length === 0 || this.deployService.result[i].linkText === 'Pantera Detect') {
      this.deployService.tableType = 'item';
    }else{
      this.deployService.tableType = 'lista';
    }
  }

  toggleAll() {
    this.expandAll = !this.expandAll;
    for (const resultado of this.deployService.result) {
      resultado.menu = this.expandAll;
    }
  }

  sendDataForm(data, menuLoaded) {
    this.deployService.valorDesejado = data;
    this.deployService.dataTable = data;
    console.log('data table',this.deployService.dataTable);

    this.deployService.dataLoaded = menuLoaded;
    this.deployService.keepCalling = menuLoaded === 'Deploy Remoto' ? true : false;
    if (Array.isArray(this.deployService.dataTable)) {
      this.deployService.tableType = 'lista';
    } else {
      this.deployService.tableType = 'item';
    }
  }

  formatMenus(arrayData){
    let linkText, parentLink, data, submenu = [ ...arrayData ];
    let result =   {linkText, parentLink, menu: false, data, submenu };
    return result;
  }

  checkDataType(){
    // console.log('data loaded', this.deployService.dataLoaded);
    return this.deployService.dataLoaded === 'Deploy Remoto' || this.deployService.dataLoaded === 'Deploy Local';
  }

  onChkBoxChange(e: MouseEvent, item: string) {
    if (['model', 'model2'].includes(item)){
      this.deployService.modules['model2'] = !this.deployService.modules['model2'];
      this.deployService.modules['model'] = !this.deployService.modules['model'];
    }else{
      this.deployService.modules[item] = !this.deployService.modules[item];
    }
  }

  checkValueBox(elem): boolean{
    return this.deployService.modules[elem];
  }

  async copyToClipboard(item) {
    const endpointPS1 = this.deployService.portainerData.filter((x)=> x['id_endpoint'] == item.id_endpoint)[0];
    const text = this.deployService.createDockerCmdPortainer(endpointPS1['id_edge'], endpointPS1['key_edge']);
    this.clipboardService.copyFromContent(text);
    alert('Comando para deploy do portainer foi copiado');
  }


  checkPullComplete() {
    if( Object.values(this.deployService.modules).every(value => value === false) ){
      return false
    }
    else {
      return Object.entries(this.deployService.modules).every(([key, value]) => {
        return value === true ? this.deployService.modulesImages[key]  === true : true;
      });
    }
  }

  atualizarDadosRemoto(){
    this.socket.sendAtualizarDadosRemoto();
  }

  ngOnDestroy(): void {
    this.deployService.keepCalling = false;
  }
}


