import { Injectable } from '@angular/core';

import { UserDataService } from '../services/user-data.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import * as amplitude from "@amplitude/analytics-browser";

const base = 'Base';
const report = 'Relatórios'
const camera = 'Câmeras'
const sat = 'Detecção Satelital'
const fm = 'Recursos'
const risk = 'Risco'
const historical_data = 'Dados Históricos'
const bio = 'Biodiversidade'
const deforestation = 'Desmatamento'
const ugm = 'umgrauemeio'
const settings = 'Minha Conta'

const amplitudePrdK = 'cade42308bd4aa9011f79945f16a5417';
const amplitudeDevK = '35b1fca2b7553566dd31daa1c6ce0167';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  
  private amplitudeKey:string;

  constructor(
    public user: UserDataService,
    private translate: TranslateService,
  ) { 
    this.setUserProperties();
    this.initKey();
  }
  

  // [to do - mover dicionários para fora do serviço]
  public groupName = {
    'base': base, // camada
    'reports': report, // camada e painel
    'visibility': camera, // camada
    'cams': camera, // painel
    'sat': sat, //camada e painel
    'fm': fm, // camada
    'brigades': fm, // painel
    'risk': risk, // camada
    'weather': risk, // painel
    'historicalData': historical_data, // camada
    'historical-data': historical_data, // painel
    'bio': bio, // camada e painel
    'deforestation': deforestation, // camada e painel
    'ugm': ugm,
    'settings': settings
  }

  public layerGroupIdToGroupName = {
    1: base, // MAP.TALHAO_LAYERS
    6: base, // MAP.LINE_LAYERS
    22: base, // MAP.PROTECTED_AREAS
    23: base, // MAP.OTHER_LAYERS    
    3: report, // MAP.REPORTS
    24: report, // SAT.REPORT_IMAGE_GROUP
    25: report, // REP.REPORT_SCAR_GROUP
    2: camera, // MAP.INFRASTRUCTURE
    7: camera, // MAP.VISIBILITY_MAP
    4: sat, // SAT.ACTIVE_FIRE_GROUP
    10: sat, // SAT.DETECTION_HISTORY_GROUP
    14: sat, // SAT.ORBIT_TRACK_GROUP
    18: sat, // SAT.ROI_GROUP
    21: sat, // SAT.HOTSPOT_WORLD_GROUP
    8: fm, // MAP.FLEET_TRACKING
    11: fm, // MAP.POINTS_OF_INTEREST
    5: risk, // MAP.FIRE_RISK
    80: risk, // MAP.CLIMATE
    82: risk, // MAP.WEATHER_STATIONS
    9: historical_data, // MAP.HOTSPOT_HISTORY
    15: historical_data, // MAP.SCAR_GROUP
    16: deforestation, // DEF.DEFORESTATION
    37: deforestation, // DEF.FAST_ALERTS
  	13: bio, // MAP.BIODIVERSITY
    83: bio, // MAP.CAMERA_TRAP
    0: ugm, // umgrauemeio
    19: ugm, // Infraestrutura
    20: ugm, // Preservação
    81: ugm, // Risco de incêndio - outros
  }

  public resourceName = {
    'brigade': 'Brigada',
    'poi': 'Ponto de interesse',
    'contact': 'Contato'
  }

  initKey(){
    if(environment.production){
      this.amplitudeKey = amplitudePrdK
    } else {
      this.amplitudeKey = amplitudeDevK
    }
  }

  sendEvent(eventName: string, eventProperties?){
    let userId = this.user.getUserId();
    let instance = amplitude.init(this.amplitudeKey, userId, {
      defaultTracking: false,
    });
    if (eventProperties){
      amplitude.track(eventName, eventProperties);
    } else {
      amplitude.track(eventName);
    }
  }

  setUserProperties(){
    const identify = new amplitude.Identify();
    try {
      identify
        .set('Cliente', this.user.planta.nome_cliente)
        .set('Planta', this.user.planta.nome_planta)
        .set('ID Planta', this.user.getIdPlanta())
        .set('Usuário', this.user.getUsername())
        .set('E-mail', this.user.getEmail())
        .set('UGM', this.isUgm())
        .set('Tipo Usuário', this.user.getUserRole())
        .set('ID Tipo Usuário', this.user.getIdRole())
        .set('Versão', environment.version)
      amplitude.identify(identify);
    } catch {}
  }

  sendTabViewEvent(groupName, tabs, selectedTab){
    let tab: string = tabs[selectedTab];
    let eventProperties = {
      "Grupo": this.groupName[groupName],
      "Aba": tab
    };
    this.sendEvent('Viu Aba', eventProperties);
  }

  sendSubTabViewEvent(groupName, tabName, subTabs, selectedSubTab, subTabNameKey){
    let subTab: string = subTabs.find(subTab => subTab.id === selectedSubTab);
    let subTabName = subTab ? subTab[subTabNameKey] || '' : '';
    let eventProperties = {}
    if (this.translate.currentLang === 'pt-br') {
      eventProperties = {
        'Grupo': this.groupName[groupName],
        'Aba': this.translate.instant(tabName),
        'Sub-Aba': this.translate.instant(subTabName)
      }
    }
    else {
      let currentLang = this.translate.currentLang;
      this.translate.use('pt-br');
      eventProperties = {
        'Grupo': this.groupName[groupName],
        'Aba': this.translate.instant(tabName),
        'Sub-Aba': this.translate.instant(subTabName)
      }
      this.translate.use(currentLang);
    }
    this.sendEvent('Viu Sub-Aba', eventProperties);
  }

  sendDatePickerEvent(groupName: string, tabName: string, period: string) {
    let eventProperties = {}
    if (this.translate.currentLang === 'pt-br') {
      eventProperties = {
        'Grupo': this.groupName[groupName],
        'Aba': this.translate.instant(tabName),
        'Período': period
      }
    }
    else {
      let currentLang = this.translate.currentLang;
      this.translate.use('pt-br');
      eventProperties = {
        'Grupo': this.groupName[groupName],
        'Aba': this.translate.instant(tabName),
        'Período': period
      }
      this.translate.use(currentLang);
    }
    this.sendEvent('Alterou Calendário', eventProperties);   
  }

  sendLayerStatusEvent(eventName: string, lgroup: any, candidateLayer: any) {
    const eventProperties = {
      'Grupo': this.layerGroupIdToGroupName[lgroup.groupId],
      'Subgrupo': this.translate.instant(lgroup.groupTitle),
      'Camada': this.translate.instant(candidateLayer.legend)
    };
    this.sendEvent(eventName, eventProperties);
  }

  isUgm(){
    if (this.user.isUgmAmplitude()){
      return 'Sim'
    }
    return 'Não'
  }

}
