import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { zoom2DistanceFov } from '../../services/cam.store'
import { Httpv2Service } from '../../services/httpv2.service';
import { MapboxService } from 'src/app/interface/services/mapbox.service';
import { DetectionsService } from './detections.service';
import { BroadcastService } from '../services/broadcast.service';

export const DEFAULT_MIN_FOCAL_DISTANCE_MM = 4.3
export const DEFAULT_SENSOR_WIDTH_MM = 5.3

type CamerasLogs = {
    camera_name: string,
    datetime: string,
    id_camera: number,
    ptz: {
        pan: string,
        tilt: string,
        zoom: number,
    }
}

@Injectable({
  providedIn: 'root'
})
export class CamerasService {

    constructor(
        public httpv2: Httpv2Service,
        private mapboxService: MapboxService,
        private detectionsService: DetectionsService,
        private broadcastService: BroadcastService
    ) { }

    // --------------------------------- Service class attributes ---------------------------------

    public camerasList = signal([])
    public camerasLogs = signal<CamerasLogs[]>([])
    public camerasLogs$ = toObservable(this.camerasLogs)
    public selectedMainCameraStreamingId = signal<number | null>(null)
    public selectedAuxCameraStreamingId = signal<number | null>(null)
    public fullScreenStreamingCameraId = signal<number | null>(null)
    public blockedCameras = signal([])
    public blockedCameras$ = toObservable(this.blockedCameras)
    public camerasStatus = signal({})
    public mainCameraEstimatedDistances = signal<string[]>([])
    public presetsData = signal<Record<string, any[]>>({})

    // --------------------------------- API calls methods ---------------------------------
    getCameras(): Observable<any> {
        return this.httpv2.getFromBackendP2('/cameras/')
    }
    
    getPTZ(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/get_ptz`)
    }

    getFrame(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/get_snap`)
    }

    moveUp(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/move_up`)
    }

    moveDown(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/move_down`)
    }

    moveLeft(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/move_left`)
    }

    moveRight(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/move_right`)
    }

    zoomIn(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/zoom_in`)
    }

    zoomOut(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/zoom_out`)
    }

    resetZoom(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/reset_zoom`)
    }

    wiper(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/wiper`)
    }

    restart(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/restart`)
    }

    pan360(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/pan_360`)
    }

    night(cameraId: number, state: boolean) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/night/${state}`)
    }

    stop(cameraId: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/stop`)
    }

    fetchCamerasLogs(){
        return this.httpv2.getFromBackendP2(`/cameras/logs`)
    }

    centerInRoi(cameraId: number, relX: number, relY: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/center_in_roi/${relX}/${relY}`)
    }

    zoomInRoi(cameraId: number, startX: number, startY: number, finalX: number, finalY: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/zoom_in_roi/${startX}/${startY}/${finalX}/${finalY}`)
    }

    absolutePTZMovement(cameraId: number, pan: number, tilt: number, zoom: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/absolute_ptz/${pan}/${tilt}/${zoom}`)
    }

    absolutePanMovement(cameraId: number, pan: number) {
        return this.httpv2.getFromBackendP2(`/cameras/${cameraId}/absolute_pan/${pan}`)
    }


    // --------------------------------- State change and getters methods ---------------------------------
    updateCamerasStatus(data: any) {
        this.camerasStatus.set(data)
    }
    
    updateCamerasLogs(logs: any) {
        this.camerasLogs.set(logs)        
    }

    addCameraToBlockedList(cameraId: number) {
        this.blockedCameras.update((existingIds) => [...existingIds, cameraId])
    }

    removeCameraFromBlockedList(cameraId: number) {
        let newArray = this.blockedCameras().filter(id => id != cameraId)
        return this.blockedCameras.set(newArray)
    }

    setSelectedMainCameraStreaming(cameraId: number) {
        this.selectedMainCameraStreamingId.set(cameraId)
        localStorage.setItem('MAIN_CAMERA_ID', cameraId.toString())
    }

    setSelectedAuxCameraStreaming(cameraId: number | null) {
        if (cameraId) {
            this.selectedAuxCameraStreamingId.set(cameraId)
            localStorage.setItem('AUX_CAMERA_ID', cameraId.toString())
            return
        }
        this.selectedAuxCameraStreamingId.set(null)
        localStorage.removeItem('AUX_CAMERA_ID')
    }

    setFullScreenStreamingCameraId(cameraId: number | null) {
        this.fullScreenStreamingCameraId.set(cameraId)
    }

    async updateMainCameraEstimatedDistances(){
        const distances = await this.calcMainCameraEstimatedDistances()
        this.mainCameraEstimatedDistances.set(distances)
    }
    
    getCameraObject(cameraId: number) {
        return this.camerasList().find((cam) => cam.id == cameraId)
    }

    selectMainCamera(cameraId: number) {
        if (cameraId === this.selectedMainCameraStreamingId()) return

        this.detectionsService.disableGeolocButtons()
        this.mapboxService.toggleMapbox(false)
        this.detectionsService.updateIsActivatedLocateFireByClickingOnMapState(false)
        this.detectionsService.updateIsActivatedLocateFireByClickingOn3DMapState(false)
        this.broadcastService.resetGeolocStates()

        if (cameraId != this.selectedMainCameraStreamingId()) {
            if (this.selectedAuxCameraStreamingId()) {
                this.setSelectedAuxCameraStreaming(null)
            }
            this.setSelectedMainCameraStreaming(cameraId)
        }
        localStorage.setItem('MAIN_CAMERA_ID', cameraId.toString())
    }

    // --------------------------------- Utility methods ---------------------------------

    async calcMainCameraEstimatedDistances(){
        const mainCameraId = this.selectedMainCameraStreamingId()
        const mainCameraObject = this.getCameraObject(mainCameraId)

        if (!mainCameraObject) {
          return [null, null]
        }
    
        const camLog = this.camerasLogs().filter(camLog => camLog.id_camera == mainCameraId)[0]
        const zoom = camLog?.ptz?.zoom ?? 1
    
        const minFocalDistance = mainCameraObject?.min_focal_distance_mm ?? DEFAULT_MIN_FOCAL_DISTANCE_MM
        const sensorWidth = mainCameraObject?.sensor_width_mm ?? DEFAULT_SENSOR_WIDTH_MM

        const { start: minDist, end: maxDist } = zoom2DistanceFov(zoom, minFocalDistance, sensorWidth)
    
        return [minDist.toFixed(2), maxDist.toFixed(2)]
    }

    setDataBeforeUpdateAutoDetStatus(cameraId: number) {
        const { _rev, id, auto_det, spin, night_mode, health } = this.camerasStatus()[cameraId]

        if (!health && !auto_det) {
            return null
        }

        let cameraStatusObject = {
            id,
            auto_det,
            spin,
            night_mode,
            health
        }

        if (!health && auto_det) {
            cameraStatusObject.auto_det = false
            const updateCameraStatusObject = {
                _id: `status_cam_${id}`,
                data: cameraStatusObject,
                _rev
            }
            return updateCameraStatusObject
        }

        cameraStatusObject.auto_det = !cameraStatusObject.auto_det
        if (cameraStatusObject.auto_det) {
            cameraStatusObject.spin = false
            this.stop(cameraId).subscribe({
                next: (response) => {
                    console.log("Stopping camera...", response)
                },
                error: (err) => {
                    console.log("Error stopping camera", err)
                }
            })
            this.detectionsService.disableGeolocButtons()
            this.detectionsService.resetGeolocStates()
            this.broadcastService.resetGeolocStates()
            this.mapboxService.toggleMapbox(false)
            this.setSelectedAuxCameraStreaming(null)
        }
        const updateCameraStatusObject = {
          _id: `status_cam_${id}`,
          data: cameraStatusObject,
          _rev
        }

        return updateCameraStatusObject
    }
}