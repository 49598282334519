<div class="page-buttons">
    <button mat-fab extended class="deploy-btn"(click)="atualizarDadosRemoto()" >
      <mat-icon>cloud_download</mat-icon> 
      Att dados remotamente 
    </button>
    <button mat-fab extended class="deploy-btn"(click)="getPortainerEndpoints()" >
      <mat-icon>sync</mat-icon> 
      Atualizar Página
    </button>
  </div>

@if(loaded){
    @for(endpoint of endpointList; track endpoint){
        <app-portainer-endpoint [endpointData] = endpoint></app-portainer-endpoint>
    }
}

