<main>
    <div class="endpoint-header">
        <h1>{{endpointData.equipamento}}</h1>
        @if(!endpointOnline){
            <span style="margin-left: 15px;" matTooltip="Endpoint Offline">🔴</span>
        }
        <span style="float: right; margin-left: 3rem;">Atualizado: {{ lastUpdated | date:'HH:mm:ss' }}</span>
        @if(this.downloadedImagesLenght()){
            <span style="float: right; margin-left: 3rem;">Imagens baixadas: {{downloadedImagesLenght()}} / {{downloadedImagesSize() | number:'1.2-2'}}Gb</span>
        }

    </div>
    <div class="portainer_install">
        <button mat-fab extended class="deploy-btn"(click)="createDockerCmdPortainer()" >
            <mat-icon>terminal</mat-icon> 
            Portainer installer
        </button>
        <button mat-fab extended class="deploy-btn"(click)="goToPortainer()" >
            <mat-icon>open_in_new</mat-icon> 
            Ir p/ Portainer
        </button>
    </div>

    @if(loading){
    <mat-spinner></mat-spinner>
    }@else {
        @if(!endpointOnline){
            <div>Endpoint Offline, clique em "Atualizar Página"</div>
        }@else {
            <div class='tables-container'>    
                <div class="column">
                    <table mat-table [dataSource]="stacksDataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="stack">
                        <th mat-header-cell *matHeaderCellDef>stack</th>
                        <td mat-cell *matCellDef="let element">{{ element.stack }}</td>
                    </ng-container>
                    <ng-container matColumnDef="version">
                        <th mat-header-cell *matHeaderCellDef>version</th>
                        <td mat-cell *matCellDef="let element">{{ element.version }}</td>
                    </ng-container>
                    <ng-container matColumnDef="downloaded">
                        <th mat-header-cell *matHeaderCellDef>downloaded</th>
                        <td mat-cell *matCellDef="let element">
                            @if(element.downloaded) {
                                ✅
                            } @else {
                                @if(!element.downloading) {
                                    <button mat-mini-fab class="deploy-btn" matTooltip="Baixar Imagem" (click)="pullDockerImage(element)">
                                        ⬇️
                                    </button>
                                } @else {
                                    <mat-icon>downloading</mat-icon>
                                }
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="running">
                        <th mat-header-cell *matHeaderCellDef>running</th>
                        <td mat-cell *matCellDef="let element"> 
                            @if(element.running){
                                ✅  
                            }@else{
                                ❌
                            }
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="deploy">
                        <th mat-header-cell *matHeaderCellDef>deploy</th>
                        <td mat-cell *matCellDef="let element"> 
                            @if(element.downloaded) {
                                <button mat-mini-fab class="deploy-btn" matTooltip="Deploy container" (click)="deployDockerStack(element)">
                                    <mat-icon [ngClass]="element.deployRunning ? 'deploy-running' : 'play-icon'">play_arrow</mat-icon>
                                </button>
                            } @else {
                                ❌
                            }
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="stacksTableColums"></tr>
                    <tr mat-row *matRowDef="let row; columns: stacksTableColums"></tr>
                    </table>
                </div>
                @if(containers){
                <div class="column">
                    <table mat-table [dataSource]="containers" class="mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>name</th>
                            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef>state</th>
                            <td mat-cell *matCellDef="let element">
                                <span style="font-weight: bold;" [style.color]="element.state === 'running' ? 'green' : 'orange'">
                                    {{element.state}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>status</th>
                            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                        </ng-container>
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef>image</th>
                            <td mat-cell *matCellDef="let element">{{ element.image }}:{{ element.tag}}</td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="containersTableColums"></tr>
                        <tr mat-row *matRowDef="let row; columns: containersTableColums"></tr>
                    </table>
                </div>
        
                }
            </div>
        }

    }


</main>
  