import { Injectable, signal } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { LoggingService } from './logging.service';

export const brigadeIconMap = {
  0: '/assets/icons/firefighter.png',
  1: '/assets/icons/motorcycle.png',
  2: '/assets/icons/car.png',
  3: '/assets/icons/pickup.png',
  4: '/assets/icons/van.png',
  5: '/assets/icons/quad.png',
  11: '/assets/icons/firetruck.png',
  12: '/assets/icons/bus.png',
  13: '/assets/icons/tractor.png',
  14: '/assets/icons/pickup-truck.png',
  21: '/assets/icons/helicopter.png',
  31: '/assets/icons/boat.png'
}

@Injectable({
  providedIn: 'root'
})
export class BrigadesService {

  constructor(
    private http:HttpService,
    private user:UserDataService,
    public logging: LoggingService
  ) { }

  public brigades_data;
  public getDataOnBrigadeRegister = signal<boolean | undefined>(undefined)
  async getBrigades(){
    this.brigades_data = this.user.getDadosPantera('brigades')
  }

  getBrigadeStatus(lastUpdated: Date): { icon: string, tooltip: string, statusValue: number } {
    if (!lastUpdated) return { icon: '', tooltip: '', statusValue:2 };
  
    const now = new Date();
    const twoHoursAgo = new Date(now.getTime() - 2 * 60 * 60 * 1000);
    const seventyTwoHoursAgo = new Date(now.getTime() - 72 * 60 * 60 * 1000);
  
    if (lastUpdated > twoHoursAgo) {
      return {
        icon: '/assets/icons/green-dot.png',
        tooltip: "MAP.LAST_UPDATE_BRIGADE_LESS",
        statusValue: 0
      };
    } else if (lastUpdated > seventyTwoHoursAgo) {
      return {
        icon: '/assets/icons/red-dot.png',
        tooltip: "MAP.LAST_UPDATE_BRIGADE_MORE",
        statusValue:1
      }
    } else {
      return {
        icon:'/assets/icons/gray-dot.png',
        tooltip:'Offline',
        statusValue: 2
      }
    };
  }

  // TODO tipar direitinho
  async calcBrigadesFireDistance(brigades: Array<any>, fireLat: number, fireLon: number){
    const brigadasAtualizadas = await this.http.maestroGet(`get_lat_lon_brigadas_atualizadas/${this.user.getIdPlanta()}`);
    if (!brigadasAtualizadas || !brigadasAtualizadas.length) return brigades;

    let locations = [] // locations de todas as brigadas + a do incendio

    for (let brigada of brigadasAtualizadas){
      locations.push([brigada?.lon, brigada?.lat]);
    };
    locations.push([fireLon, fireLat]);
    let distancesList = [];
    
    try {
      const routeApiObj = {"locations": locations, "destinations": [locations.length - 1], "metrics": ["distance"]};
      const routeApiResponse = await this.http.openRoutePost('matrix/driving-car', routeApiObj);
      distancesList = routeApiResponse.distances;
    } catch (error){
      this.logging.logERROR(`calcBrigadesFireDistance ${error}`,error);
      console.warn(`Open route API error ${error}`);
      return brigades;
    }
    
    for (let i = 0; i < brigadasAtualizadas.length; i++){
      brigadasAtualizadas[i]['fireDrivingDistanceKm'] = Number((distancesList[i][0] / 1000).toFixed(0));
    };

    brigades.forEach((brigade) => {
      const matchingBrigade = brigadasAtualizadas.filter((updatedBrigade) => brigade.id_brigada === updatedBrigade.id_brigada);
      brigade['fireDrivingDistanceKm'] = matchingBrigade.length > 0 ? matchingBrigade[0]['fireDrivingDistanceKm'] : null;
    });

    brigades = this.orderBrigadesByDistance(brigades);
    console.log('ALL brigades with sorted distance', brigades);
    return brigades;
  };

  orderBrigadesByDistance(brigades){
    brigades.sort((a, b) => {
      if (!a.fireDrivingDistanceKm) return 1; // num positivo inverte ordem
      if (!b.fireDrivingDistanceKm) return -1; // num negativo mantém ordem
      return a.fireDrivingDistanceKm - b.fireDrivingDistanceKm;
    });
    return brigades;
  };

}
