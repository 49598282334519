import { Component, OnInit, ViewChild, signal, Input } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { BrigadesService, brigadeIconMap } from '../../services/brigades.service';
import { FormControl, UntypedFormControl, Validators, FormBuilder, Form, FormArray } from '@angular/forms';
import { UserDataService } from 'src/app/services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GeoService } from '../../services/geo.service';
import { GeoLayersService } from '../../services/geo.layers';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface BrigadeType {
  id: number;
  type: string;
}
interface BrigadeStatus {
  lastUpdated?: Date
  statusIcon: string;
  statusValue: number;
  statusTooltip: string;
}
interface ViewOption {
  id: number;
  option: string;
  icon: string;
}

@Component({
  selector: 'app-brigades',
  templateUrl: './brigades.component.html',
  styleUrls: ['./brigades.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BrigadesComponent implements OnInit {

  public brigadeViewFormControl = new UntypedFormControl('');
  public nameFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(String.raw`^(?!\s*$).+`)
  ]);
  public categoryFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(String.raw`^(?!\s*$).+`)
  ]);
  public typeFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public placaFormControl = new UntypedFormControl('', []);
  public editTypeFormControl = new FormControl(0);

  public brigadeViewOptions: ViewOption[] = [
    { id: 1, option: "MAP.BRIGADES_LIST", icon: 'list' },
    { id: 2, option: "MAP.REGISTER_BRIGADE", icon: 'group_add' }
  ];
  @Input() selectedBrigadeViewOption: number = this.brigadeViewOptions[0].id;
  public newBrigadeName:string;
  public newBrigadeCategory: string;
  public types: BrigadeType[] = [];

  public status: BrigadeStatus[] = [];
  public selectedType:number;
  public placa: string;
  private newBrigade;
  public addedBrigade:boolean = false;
  public brigadeIconMap = brigadeIconMap;
  public originalData = {};
  @ViewChild(MatTable) table: MatTable<any>;
  @Input() showResourceOptions: boolean = true
  private tabs = {
    0: 'Brigadas',
    1: 'Pontos de Interesse',
    2: 'Contatos'
  }

  constructor(
    public brigades: BrigadesService,
    private http: HttpService,
    public user: UserDataService,
    private amplitude: AmplitudeService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public geo:GeoService,
    public layer: GeoLayersService,
    private fb: FormBuilder
  ) {}

  public loaded:boolean = false;
  public brigadesTableColumns: string[] = [
    'status_brigada',
    'categoria_brigada',
    'tipo_brigada',
    'nome_brigada',
    'placa',
  ];
  public brigadesTableColumnsWithExpand = [...this.brigadesTableColumns, 'expand'];
  public expandedElement: any | null;
  public editState: { [key: string]: boolean } = {};

  public searchField: string;

  public editKeys: { [key: string]: { [key: string]: string } } = {};
  public brigadeObsKeys: { [key: string]: string[] } = {};
  public form = this.fb.group({obs_brigade: this.fb.array([])})
  private obsData: any
  public checkDuplicate = signal<undefined | boolean>(undefined);
  public plateAlreadyRegistered = signal<boolean | undefined>(undefined)
  public brigadeNameAlreadyRegistered = signal<boolean | undefined>(undefined)
  @Input() isBrigadeRegistration: boolean = false;

  async ngOnInit(){
    await this.initBrigades();
    this.sortByStatus();
    this.placaFormControl.valueChanges.subscribe((value: string) => {
      this.setPlateExists(value);
    });
    this.nameFormControl.valueChanges.subscribe((value: string) => {
      this.setBrigadeNameExists(value);
    });
  }

  setPlateExists(plate: string) {
    const placas = this.brigades.brigades_data.map(brigade => brigade.placa);
    if (placas.includes(plate)) {
      this.plateAlreadyRegistered.set(true);
    } else {
      this.plateAlreadyRegistered.set(false);
    }
  }

  setBrigadeNameExists(plate: string) {
    const brigadeName = this.brigades.brigades_data.map(brigade => brigade.nome_brigada);
    if (brigadeName.includes(plate)) {
      this.brigadeNameAlreadyRegistered.set(true);
    } else {
      this.brigadeNameAlreadyRegistered.set(false);
    }
  }

  returnBrigadeObs(obs_brigada: any): string[] {
    if (!obs_brigada) {
      return [];
    }
    return this.obsData = Object.keys(obs_brigada);
  }

  async initBrigades(){
    await this.brigades.getBrigades();
    for (let b of this.brigades.brigades_data){
      b.icon_tipo_brigada = brigadeIconMap[b.id_tipo_brigada];
      if (b.obs_brigada) {
        this.brigadeObsKeys[b.id_brigada] = this.returnBrigadeObs(b.obs_brigada);
    } else {
        this.brigadeObsKeys[b.id_brigada] = [];
    }
    }
    if (this.brigades.brigades_data.length){
      this.brigades.brigades_data.sort((a,b) => this.sortAlph(a['tipo_brigada'], b['tipo_brigada']));
    }
    this.getTypes();
    this.loaded = true;
    await this.getStatus();
  }

  async getTypes(){
    let response = await this.http.maestroGet('get_tipo_brigadas');
    this.types = [];
    for (let r of response){
      this.types.push({
        id: r['id_tipo_brigada'],
        type: r['tipo_brigada']
      })
    }
  }

  async postBrigade(){
    this.newBrigade = {
      'id_planta': this.user.getIdPlanta(),
      'nome_brigada': this.newBrigadeName,
      'categoria_brigada': this.newBrigadeCategory,
      'id_tipo_brigada': this.selectedType,
    };
    let brigadeTypeName = this.types.find(type => type.id === this.selectedType).type; // nome tipo veículo p/ Amplitude

    if (this.placa){
      this.newBrigade['placa'] = this.placa;
    } else {
      this.newBrigade['placa'] = null;
    }
    console.log('new brigade:', this.newBrigade);
    this.addedBrigade = true;
    for (let fc of [this.nameFormControl, this.typeFormControl, this.categoryFormControl, this.placaFormControl]) { fc.reset() };

    let response = await this.http.maestroPost('post_brigada', this.newBrigade)
    
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.BRIGADE_REGISTERED_DIALOG")}`}
      });
      this.amplitude.sendEvent('Cadastrou Recurso', {"Recurso": this.amplitude.resourceName['brigade'], "Tipo": brigadeTypeName, "Nome": this.newBrigade['nome_brigada']});

      this.brigades.brigades_data.push({
        'categoria_brigada': this.newBrigade.categoria_brigada,
        'icon_tipo_brigada': brigadeIconMap[this.newBrigade.id_tipo_brigada],
        'nome_brigada': this.newBrigade.nome_brigada,
        'placa': this.newBrigade.placa
      })
      await this.user.updatePanteraData()
      this.initBrigades();
      this.plateAlreadyRegistered.set(false);
      this.brigadeNameAlreadyRegistered.set(false);
      this.brigades.getDataOnBrigadeRegister.set(true)
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR")}. ${this.translate.instant("MAP.GENERIC_ERROR_MESSAGE")}`}
      });
    }
    console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
  }

  cancelEdit(idBrigada: string){
    const original = this.originalData[idBrigada];
    if (original) {
        const index = this.brigades.brigades_data.findIndex(el => el.id_brigada === idBrigada);
        if (index !== -1) {
            this.brigades.brigades_data[index] = original;
        }
        this.editState[idBrigada] = false;
        delete this.originalData[idBrigada];
        this.expandedElement = this.brigades.brigades_data[index];
        this.table.renderRows();
        this.checkDuplicate.set(undefined);
    }
}

  async removeBrigade(brigada){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_BRIGADE_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (!confirmation) return
      this.brigades.brigades_data = this.brigades.brigades_data.filter(d => d != brigada)
      if (brigada.id_brigada){
        let response = await this.http.maestroPost('remove_brigada', brigada)
        this.openResponseDialog(response, brigada)
        await this.user.updatePanteraData()
        this.initBrigades()
        await this.geo.updateFleetTrackingGroup();
      }
    })
  }
  
  async removeBrigadeOrCancelEdit(brigada){
    const idBrigada = brigada.id_brigada;
    if (this.editState[idBrigada]){
        this.cancelEdit(idBrigada);
        return;
    };
    await this.removeBrigade(brigada);
}

  openResponseDialog(response, brigada){
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.BRIGADES_UPDATED_DIALOG")}`}
      });
    this.amplitude.sendEvent('Removeu Recurso', {"Recurso": this.amplitude.resourceName['brigade'], "Tipo": brigada['tipo_brigada'], "Nome": brigada['nome_brigada'], "ID": brigada.id_brigada});
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_UPDATING_BRIGADES_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }
  }

  sortData(sort: Sort) {
    const data = this.brigades.brigades_data.slice();
    if (!sort.active || sort.direction === '') {
      this.brigades.brigades_data = data;
    } else {
      this.brigades.brigades_data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const statusOrder = { '/assets/icons/green-dot.png': 1, '/assets/icons/red-dot.png': 2, '/assets/icons/gray-dot.png': 3 };
        let valueA;
        let valueB;
        if (sort.active === 'status_brigada') {
          valueA = statusOrder[a.statusIcon] || 3; 
          valueB = statusOrder[b.statusIcon] || 3;
        } else {
          valueA = a[sort.active];
          valueB = b[sort.active];
        }
        return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
      });
    }
  }

  sortByStatus() {
    this.sortData({active: 'status_brigada', direction: 'asc'});
  }

  sortAlph(a:string,b:string){
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  selectBrigade(idBrigada: string){    
    if (this.geo.layerGroupList.findIndex(l => l.groupId === 8) !== -1) {
      let groupIndex = this.geo.layerGroupList.findIndex(l => l.groupId === 8);
      if (this.geo.layerGroupList[groupIndex].layersList.findIndex(l => l.legend === 'MAP.BRIGADES' // camada existe?
          && this.layer.brigadeMarkers.find(m => m.feature.properties.id_brigada == idBrigada)) !== -1) { // brigada ativa?

        this.geo.activateLayer(8, 'MAP.BRIGADES');
        this.geo.openBrigadePopup(idBrigada);
        this.layer.selectedBrigadeId = idBrigada;

        let nomeTipoBrigada = this.layer.brigadeMarkers.find(m => m.feature.properties.id_brigada == idBrigada)?.feature.properties.nome_tipo_brigada;
        let nomeBrigada = this.layer.brigadeMarkers.find(m => m.feature.properties.id_brigada == idBrigada)?.feature.properties.nome_brigada;
        this.amplitude.sendEvent("Clicou Recurso", {"Recurso": this.amplitude.resourceName['brigade'], "Tipo": nomeTipoBrigada, "Nome": nomeBrigada, "ID": idBrigada, "Origem": "Tabela"});
      }
    }
  }

  
  get obs_brigade(): FormArray {
    return this.form.get('obs_brigade') as FormArray;
  }

  checkDuplicateKey(value: string) {
    return this.checkDuplicate.set(this.obsData.includes(value));
  }

  deleteNewObsBrigada (index:number){
    this.obs_brigade.removeAt(index)
  }

  addObsBrigada(key: string = '', value: string = '') {
    const newFields = this.fb.group({
      Chave: [key, Validators.required],
      Valor: [value, Validators.required]
    });
  
    newFields.get('Chave').valueChanges.subscribe(value => {
      this.checkDuplicateKey(value);
    });
  
    this.obs_brigade.push(newFields);
  }

  disableSave(element: any): boolean {
    if (!this.editState[element.id_brigada]) {
      return false;
    }
    return this.obs_brigade.controls.some(item => item.invalid);
  }
  
  removeObsBrigada(idBrigada: string, key: string) {
    delete this.editKeys[idBrigada][key];
    delete this.brigades.brigades_data.find(brigada => brigada.id_brigada === idBrigada).obs_brigada[key];
    const keyIndex = this.brigadeObsKeys[idBrigada].indexOf(key);
    if (keyIndex > -1) {
      this.brigadeObsKeys[idBrigada].splice(keyIndex, 1);
    }
  }
  
async toggleEdit(element: any) {
  const idBrigada = element.id_brigada;
  this.editState[idBrigada] = !this.editState[idBrigada];

  if (this.editState[idBrigada]) {
      this.obs_brigade.clear(); 
      
      if (!element.obs_brigada) {
          element.obs_brigada = {};
      }
      this.originalData[idBrigada] = { ...element, obs_brigada: { ...element.obs_brigada } };
      this.editKeys[idBrigada] = {};
      for (let key of this.returnBrigadeObs(element.obs_brigada)) {
          this.editKeys[idBrigada][key] = key;
      }
      this.disableSave(element); 
  } else {
      const updatedBrigade = this.brigades.brigades_data.find(brigada => brigada.id_brigada === idBrigada);
      let brigadeTypeName = this.types.find(type => type.id === element.id_tipo_brigada).type;
      if (updatedBrigade.placa == '') updatedBrigade.placa = null;
      if (updatedBrigade.categoria_brigada == '') updatedBrigade.categoria_brigada = this.originalData[idBrigada].categoria_brigada;
      if (updatedBrigade.nome_brigada == '') updatedBrigade.nome_brigada = this.originalData[idBrigada].nome_brigada;
      if (updatedBrigade.obs_brigada == '') updatedBrigade.obs_brigada = this.originalData[idBrigada].obs_brigada;
      const newObsBrigada = {};
      for (let key of this.returnBrigadeObs(this.editKeys[idBrigada])) {
          newObsBrigada[this.editKeys[idBrigada][key]] = updatedBrigade.obs_brigada[key];
      }
      updatedBrigade.obs_brigada = newObsBrigada;

      this.obs_brigade.controls.forEach((item, index) => {
          const chave = item.get('Chave').value;
          const valor = item.get('Valor').value;
          if (chave && valor) {
              updatedBrigade.obs_brigada[chave] = valor;
          }
      });

      if (JSON.stringify(updatedBrigade) !== JSON.stringify(this.originalData[idBrigada])) {
          const editedFields = this.getBrigadeEditedInfo(this.originalData[idBrigada], updatedBrigade);
          await this.http.maestroPost('update_brigada', updatedBrigade);
          const updatedBrigadeData = this.brigades.brigades_data.find(b => b.id_brigada === idBrigada);
          const brigadeIndex = this.brigades.brigades_data.findIndex(b => b.id_brigada === idBrigada);
          if (brigadeIndex !== -1) {
              this.brigades.brigades_data[brigadeIndex] = updatedBrigadeData;
          }
          const isExpanded = this.expandedElement ? this.expandedElement.id_brigada : null;
          await this.user.updatePanteraData();
          await this.initBrigades();

          if (isExpanded) {
              this.expandedElement = this.brigades.brigades_data.find(b => b.id_brigada === isExpanded) || null;
          }
          
          this.table.renderRows();
          this.sortByStatus();
          await this.geo.updateFleetTrackingGroup();
          this.selectBrigade(idBrigada);
          this.amplitude.sendEvent('Editou Recurso', {"Recurso": this.amplitude.resourceName['brigade'], "Tipo": brigadeTypeName, "Nome": updatedBrigade['nome_brigada'], "Editado": editedFields, "ID": idBrigada});
      }
  }
}


getBrigadeEditedInfo(original: any, updated: any): { [key: string]: any[] }[] {
  const editedFields: { [key: string]: any[] }[] = [];
  for (const key in original) {
    if (original.hasOwnProperty(key) && updated.hasOwnProperty(key)) {
      if (original[key] !== updated[key] && key !== 'icon_tipo_brigada') {
        editedFields.push({ [key]: [original[key], updated[key]] });
      }
    }
  }
  return editedFields;
}

  onBrigadeTypeChange(element: any, typeId: number) {
    element.id_tipo_brigada = typeId;
    element.icon_tipo_brigada = this.brigadeIconMap[typeId];
  }

  async getStatus(){
    let response = await this.http.maestroGet(`get_brigadas/${this.user.getIdPlanta()}`);
    if(Array.isArray(response)){
      for (let r of response){
        const statusInfo = this.brigades.getBrigadeStatus(new Date(r['last_updated']));
        const brigade = this.brigades.brigades_data.find(b => b.id_brigada === r.id_brigada); 
        if (brigade) {
          brigade.statusIcon = statusInfo.icon; 
          brigade.statusTooltip = statusInfo.tooltip;
        }
      }
    }
  }

  amplitudeTabEvent(selectedTab){
    this.amplitude.sendTabViewEvent('brigades', this.tabs, selectedTab);
  }

  amplitudeSubTabEvent(selectedSubTab){
    this.amplitude.sendSubTabViewEvent('brigades', 'MAP.BRIGADES', this.brigadeViewOptions, selectedSubTab, 'option')
  }

  removeSpaces(element?: any) {
    if (element && element.placa) {
      element.placa = element.placa.replace(/\s/g, '');
    } else if (this.placa) {
      this.placa = this.placa.replace(/\s/g, '');
    }
  }
  
}