export const environment = {
  version: '5.8.54.0-b',
  production: false,
  api: 'https://dev.maestro.umgrauemeio.com',
  // api: 'http://localhost:5001',
  geoserverUrl: 'https://geo2.umgrauemeio.com',
  forefireUrl: 'https://forefire.umgrauemeio.com',
  // forefireUrl: 'http://localhost:999',
  camsCloudApiUrl: 'https://cams.umgrauemeio.com',
  // camsCloudApiUrl: 'http://127.0.0.1:8000',
  // projectsApiUrl: 'https://proj.umgrauemeio.com',
  // streamingApiUrl: 'http://localhost:3020',
  streamingApiUrl: 'https://streaming.umgrauemeio.com',
  socketCentralUrl: 'https://socketcentral.umgrauemeio.com',
  projectsApiUrl: 'https://proj.umgrauemeio.com',
  logName: 'pweb.dev',
  // azureRedirectUri: 'http://localhost:4202/',

  // DEV PARAMS
  setStreamingIpMode: undefined,
  localhostMode: true
};