import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from "socket.io-client"
import { environment } from 'src/environments/environment';
import { UserDataService } from './user-data.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})

export class SocketService {
  private socketStream: Socket;
  private pwebSpace: Socket;

  constructor(
    public user: UserDataService,
    public logging: LoggingService
  ) {

  }

  public socketDownValue = false;
  public reconnectAttempts = 0
  public maxReconnectAttempts = 10
  private socketOptions = { 
    reconnection: true,
    reconnectionAttempts: this.maxReconnectAttempts,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 7000,
    auth: { token: this.user.getSocketCentralToken()},
    transports: ["websocket"]
  }

  onFrame(camId: number): Observable<ArrayBuffer> {
    // stream pantanal ativado
    this.socketStream = io(environment.streamingApiUrl);
    return new Observable((observer) => {
      this.socketStream.on(`frame_${camId}`, (image) => {
        observer.next(image);
      });
    });
  }


  // CONNECTION pwebSpace
  joinIdPlantRoom() {
    this.reconnectAttempts = 0
    this.pwebSpace = io(environment.socketCentralUrl + '/pwebSpace', this.socketOptions);
    this.pwebSpace.on("send_room", (attempt) => {
      this.reconnectAttempts = 0;
      this.pwebSpace.emit('join', this.user.getIdPlanta() );
      this.logging.logINFO(`${this.pwebSpace.id}: Usuário conectado ao socket central.`);
    });
  }

  // HANDLERS socketSat
  onNewSatData() {
    return new Observable<Boolean>(observer => {
      this.pwebSpace.on('sat_update', msg => {
        this.logging.logINFO(`${this.pwebSpace.id}: Solicitação de atualização de dados satelital recebida.`);
        observer.next(msg);
      });
    });
  }

  // HANDLERS socketBrigade
  onNewBrigadetData() {
    return new Observable<Boolean>(observer => {
      this.pwebSpace.on('brigade_update', msg => {
        console.log(`WebSocket: Solicitação de atualização de dados de brigadas recebida.`);
        observer.next(msg);
      });
    });
  }

  onAttDadosEvent() {
    return new Observable<Boolean>(observer => {
      this.pwebSpace.on('trigger_att_dados_planta', msg => {
        this.logging.logINFO(`${this.pwebSpace.id}: Recebeu solicitação de atualização de dados da planta.`);
        console.log('Recebeu solicitação de atualização de dados da planta')
        observer.next(msg);
        this.sendFeedBackAtualizarDadosRemoto();
      });
    });
  }

  //Retorno do cliente ao receber o trigger de att dados remotos
  onFeedBackAttDadosEvent() {
    return new Observable<{ success: boolean; numClients: number}>(observer => {
      this.pwebSpace.on('trigger_feedback_atualizar_dados_planta', msg => {
        console.log(`Dados atualizados no lado do cliente. Número de clientes: ${msg.numClientes}`);
        observer.next(msg);
      });
    });
  }
  

  onSocketCentralDisconnect() {
    return new Observable<String>(observer => {
      this.pwebSpace.on('disconnect', msg => {
        observer.next(msg);
      });
    });
  }

  onSocketCentralDown() {
    return new Observable<String>(observer => {
      this.pwebSpace.on('connect_error', (error: any) => {
        this.reconnectAttempts++;
        if (this.reconnectAttempts == this.maxReconnectAttempts) {
          this.logging.logERROR(`socket:onSatSocketDown ${error}`,error);
          observer.next('true');
        }
      });
    });
  }

  // EMITERS socketSat
  sendAttSatConfirmation() {
    this.logging.logINFO(`${this.pwebSpace.id}: Atualização dados satelital realizada.`);
  }


  // EMITERS socketAttDadosRemoto
  sendAtualizarDadosRemoto() {
    this.pwebSpace.emit('atualizar_dados_planta',  this.user.getIdPlanta() );
    this.logging.logINFO(`${this.pwebSpace.id}: Atualizados dados do cliente.`);
  }

  //Retorno do cliente ao receber o trigger de att dados remotos
  sendFeedBackAtualizarDadosRemoto() {
    this.pwebSpace.emit('feedback_atualizar_dados_planta',  this.user.getIdPlanta() );
  }

  
  disconnectFromSockCent() {
    try {
      this.logging.logINFO(`${this.pwebSpace.id}: Usuário se desconectou do socket central.`);
      this.pwebSpace.disconnect();
    } catch (error) {
      this.logging.logERROR(`socket:disconnectFromSockCent ${error}`,error);
    }
  }
}