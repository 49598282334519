<main>

  <section class="ctrl-bar">

    <button mat-mini-fab
      (click)='toggleBase()'
      id='baseLayers-btn'
      [ngClass]="show == 'base' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      matTooltip="{{'MAP.BASE_LAYERS' | translate}}">
      <mat-icon style="color: white;">layers</mat-icon>
    </button>

    <button mat-mini-fab
      (click)='toggleReport()'
      id='reportLayers-btn'
      [ngClass]="show == 'reports' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      matTooltip="{{'MAP.REPORTS' | translate}}">
      <mat-icon style="color: white;">local_fire_department</mat-icon>
    </button>

    <button mat-mini-fab
      (click)='toggleVisibility()'
      id='visibilityLayers-btn'
      [ngClass]="show == 'visibility' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      [disabled]='!user.hasLocais()'
      matTooltip="{{ 'MAP.CAMERAS' | translate }}">
      <mat-icon [ngStyle]="user.hasLocais() ? { 'color': 'white' } : { 'color': '$app-primary-color' }">videocam</mat-icon>
    </button>

    <button mat-mini-fab
      (click)='toggleSat()'
      id='satLayers-btn'
      [ngClass]="show == 'sat' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      [disabled]='!user.hasSatellite()'
      matTooltip="{{'SAT.MENU_TOOLTIP' | translate}}">
      <mat-icon [ngStyle]="user.hasSatellite() ? { 'color': 'white' } : { 'color': '$app-primary-color' }">satellite_alt</mat-icon>
    </button>

    <button mat-mini-fab
      (click)='toggleFm()'
      id='fmLayers-btn'
      [ngClass]="show == 'fm' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      [disabled]='!user.hasFireManagement()'
      matTooltip="{{'MAP.RESOURCES' | translate}}">
      <mat-icon [ngStyle]="user.hasFireManagement() ? { 'color': 'white' } : { 'color': '$app-primary-color' }">fire_truck</mat-icon>
    </button>

    <button mat-mini-fab
      [disabled]='!user.hasRiskMap()'
      (click)='toggleRisk()'
      id='riskLayers-btn'
      [ngClass]="show == 'risk' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      matTooltip="{{'MAP.FIRE_RISK' | translate}}">
      <mat-icon [ngStyle]="user.hasRiskMap() ? { 'color': 'white' } : { 'color': '$app-primary-color' }">thermostat</mat-icon>
    </button>

    <button mat-mini-fab
      [disabled]='!user.hasHistoricalData()'
      id='historicalDataLayers-btn'
      (click)='toggleHistoricalData()'
      [ngClass]="show == 'historicalData' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
      matTooltip="{{'MAP.HISTORICAL_DATA' | translate}}">
      <mat-icon [ngStyle]="user.hasHistoricalData() ? { 'color': 'white' } : { 'color': '$app-primary-color' }">timeline</mat-icon>
    </button>

    @if (user.hasDeforestation()) {
      <button mat-mini-fab
        id='deforestationLayers-btn'
        (click)='toggleDeforestation()'
        [ngClass]="show == 'deforestation' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
        matTooltip="{{'DEF.DEFORESTATION' | translate}}">
        <mat-icon style="color: white;">forest</mat-icon>
      </button>
    }

    @if (user.hasBio()) {
      <button mat-mini-fab
        id='bioLayers-btn'
        (click)='toggleBio()'
        [ngClass]="show == 'bio' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
        matTooltip="{{'MAP.BIODIVERSITY' | translate}}">
        <mat-icon style="color: white;">pets</mat-icon>
      </button>
    }

    @if (user.isUgm()) {
      <button mat-mini-fab
        (click)='toggleUgm()'
        [ngClass]="show == 'ugm' ? 'selected-ctrl-btn' : 'unselected-ctrl-btn'"
        matTooltip="umgrauemeio">
        <mat-icon style="color: white;">public</mat-icon>
      </button>
    }
    <div class="empty-block"></div>

  </section>

  @if (show) {
    <section class='content-wrapper'>
      @if (show == 'base' ) {
        <div>
          <div class='panel-title'>
            <mat-icon>layers</mat-icon>
            {{'MAP.BASEMAP' | translate }}
          </div>
          <mat-radio-group (change)="selectBaseLayer($event)">
            @for (baseL of geo.baseLayersList; track baseL) {
              <div class='layer'>
                <mat-radio-button [value]="baseL" [checked]="baseL.active" color="warn">
                  {{baseL.legend | translate}}
                </mat-radio-button>
              </div>
            }
          </mat-radio-group>
          <mat-divider style="margin: 8px 0;"></mat-divider>
        </div>
      }
      @for (overlayGroup of geo.filteredLayerGroupList; track overlayGroup; let i = $index) {
        <div>
          <div class='panel-title'>
            <div class="layer-group-with-info-header-container">
              <mat-icon class="overlay-group-icon">{{overlayGroup.icon}}</mat-icon>
              {{overlayGroup.groupTitle | translate}}
              <div class="info-icon-container">
                @if (overlayGroup.info) {
                  <mat-icon class="info-icon" (click)="toggleInfo(i)">info</mat-icon>
                }
              </div>
            </div>
            @if (overlayGroup.showInfo) {
              <div class="info-content" [innerHTML]="overlayGroup.infoHTML | translate"></div>
            }
            <!-- obs se quiser uma classe pra um grupo especifico, usar class="'info-content-' + i" -->

            <!-- Detecção satelital em relatório -->
            @if (overlayGroup['groupId'] === 12) {
              <div>
                @if (geo.reportSatDetectionGroupHasNoLayer()) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'SAT.NO_REPORT_SATELLITE_DETECTION_DATA' | translate }}
                  </mat-card>
                }
              </div>
            }

            <!-- Imagens satelitais em relatório -->
            @if (overlayGroup['groupId'] === 24) {
              <div>
                @if (geo.reportSatImageGroupHasNoLayer()) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'SAT.NO_REPORT_IMAGE' | translate }}
                  </mat-card>
                }
              </div>
            }

            <!-- Cicatriz em relatório -->
            @if (overlayGroup['groupId'] === 25) {
              <div>
                @if (geo.reportScarGroupHasNoLayer()) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'REP.NO_REPORT_SCAR' | translate }}
                  </mat-card>
                }
              </div>
            }

            <!--Detecções satelitais ativas-->
            @if (overlayGroup['groupId'] == 4) {
              <div>
                @if (hasNotLayer(overlayGroup, 4)) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'SAT.NO_ACTIVE_FIRE_DATA' | translate }}
                  </mat-card>
                }
              </div>
            }
            <!--Histórico de detecções satelitais-->
            @if (overlayGroup['groupId'] == 10) {
              <div>
                @if (hasNotLayer(overlayGroup, 10)) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'SAT.NO_DETECTION_HISTORY_DATA' | translate }}
                  </mat-card>
                }
                @if (this.sat.hotspotTalhao.length == this.sat.maxHotspotTalhao) {
                  <mat-card class="data-truncated-card" appearance="outlined">
                    {{ 'SAT.HOTSPOT_DETECTION_HISTORY_DATA_TRUNCATED' | translate }}
                  </mat-card>
                }
              </div>
            }
            <!--Rastreamento de frotas-->
            @if (overlayGroup['groupId'] == 8) {
              <div>
                @if (hasNotLayer(overlayGroup, 8)) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'MAP.NO_FLEET_TRACKING_DATA' | translate }}
                  </mat-card>
                }
              </div>
            }
            <!--Pontos de interesse (POI)-->
            @if (overlayGroup['groupId'] == 11) {
              <div>
                @if (hasNotLayer(overlayGroup, 11)) {
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'MAP.NO_POINT_OF_INTEREST_DATA' | translate }}
                  </mat-card>
                }
              </div>
            }
          </div>
          @for (layerObj of overlayGroup.layersList; track layerObj; let isLast = $last) {
            <div class='layer'>
              <!--ícone ao lado do texto das camadas do subgrupo Infraestrutura-->
              @if (overlayGroup['groupId'] == 2 && (layerObj.legend == "MAP.MONITORING" || layerObj.legend == "MAP.MONITORING_IHP")) {
                <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
                  {{ layerObj.legend | translate }} <img src="assets/icons/icon-tower-green.png" class="infrastructure-aligned-icon" style="width: 22px; height: 22px;"/>
                </mat-checkbox>
              } @else if (overlayGroup['groupId'] == 2 && layerObj.legend == "MAP.CENTRAL") {
                <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
                  {{ layerObj.legend | translate }} <img src="assets/icons/cco.png" class="infrastructure-aligned-icon" style="width: 22px; height: 22px;"/>
                </mat-checkbox>
              } @else if (overlayGroup['groupId'] == 2 && layerObj.legend == "MAP.REPEATER") {
                <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
                  {{ layerObj.legend | translate }} <img src="assets/icons/icon-tower-grey.png" class="infrastructure-aligned-icon" style="width: 22px; height: 22px;"/>
                </mat-checkbox>
              } @else if (overlayGroup['groupId'] == 2 && layerObj.legend == "MAP.SECURITY") {
                <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
                  {{ layerObj.legend | translate }} <img src="assets/icons/red-pin.png" class="infrastructure-aligned-icon" style="width: 22px; height: 22px;"/>
                </mat-checkbox>
              <!--demais camadas (sem ícone)-->
              } @else {
                <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
                  {{ layerObj.legend | translate }}
                </mat-checkbox>
              }

            <!--legenda inline do subgrupo Área Monitorada (área total, área monitorada e camada propriedade)-->
            @if (overlayGroup['groupId'] == 1){
              @if(isLast && talhaoColorMap.length){
                <div>
                  <div class="talhao-legend-header">
                    {{ 'MAP.PROPERTY' | translate }}
                  </div>
                  @for (obj of talhaoColorMap; track obj.legend) {
                    <div class="talhao-legend-value">
                      <i class="talhao-legend-symbol" [ngStyle]="{'background': obj.color}"></i> {{obj.legend | translate}}
                    </div>
                  }
                </div>
              }
              @if(isLast){
                <div class="monitored-area-value">
                  <div>
                    {{'MAP.TOTAL_AREA' | translate}}: {{ monitoredAreaData.talhao_sem_sobreposicao_ha | number:'1.0-0':'pt-BR' }} ha
                  </div>
                  @if(monitoredAreaData.talhao_visivel_ha > 0){
                    <div>
                      {{'MAP.MONITORED_AREA' | translate}}: {{monitoredAreaData.talhao_visivel_ha | number:'1.0-0':'pt-BR'}} ha ({{monitoredAreaData.pct_talhao_visivel | number:'1.0-0'}}%)
                    </div>
                  }
                </div>
              }
            }

              <!--legenda inline do subgrupo Relatórios-->
              @if (overlayGroup['groupId'] == 3) {
                <div class='report-legend-header'>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_vermelho" class="red-svg"> {{reportColorMap[0].legend | translate}}
                  </div>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_laranja_escuro" class="orange-svg"> {{reportColorMap[1].legend | translate}}
                  </div>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_laranja_claro" class="amber-svg"> {{reportColorMap[2].legend | translate}}
                  </div>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_azul" class="blue-svg"> {{reportColorMap[3].legend | translate}}
                  </div>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_cinza" class="grey-svg"> {{reportColorMap[4].legend | translate}}
                  </div>
                  <div class="fx-row-start-center">
                    <img src="assets/icons/local_fire_department.svg" alt="fogo_preto" class="black-svg"> {{reportColorMap[5].legend | translate}}
                  </div>
                </div>
              }

              <!--legenda inline do subgrupo de Mapa Visibilidade-->
              @if (overlayGroup['groupId'] == 7 && isLast) {
                <div>
                  <div class='visibility-legend-header'>
                    {{ 'MAP.VISIBILITY_MAP_LEGEND_HEADER' | translate }}:<br>
                  </div>
                  <div>
                    <img src='https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=ugm:viewshed_local&LEGEND_OPTIONS=fontAntiAliasing:true'>
                  </div>
                  <div class='visibility-legend-description'>
                    {{ 'MAP.VISIBILITY_MAP_LEGEND_DESCRIPTION' | translate }}
                  </div>
                </div>
              }

              <!--legenda inline do subgrupo Detecções Ativas-->
              @if (overlayGroup['groupId'] == 4 && isLast) {
                <div>
                  <div class='active-fire-legend-header'>
                    {{ 'SAT.ACTIVE_FIRE_LEGEND_HEADER' | translate }}:<br>
                  </div>
                  <div class='active-fire-legend-subheader'> {{ 'SAT.ACTIVE_FIRE_LEGEND_SUBHEADER' | translate }}</div>
                  @for (obj of satColorMap; track obj.legend) {
                    <div class="active-fire-legend-value">
                      <i [ngStyle]="{'background': obj.color}"></i> {{obj.legend | translate}}
                    </div>
                  }
                </div>
              }

              @if ((layerObj.legend == "SAT.ROI_POLY_LAYER" && hasNotHotspotRoiLayer(overlayGroup))) {
                <div>
                  <mat-card class="mat-card-sat" appearance="outlined">
                    {{ 'SAT.NO_HOTSPOT_ROI_DATA' | translate }}
                  </mat-card>
                </div>
              }
            </div>
          }
          <mat-divider style="margin: 8px 0;"></mat-divider>
        </div>
      }
      @if (show == 'base') {
        <div class="documentation" [innerHTML]="'MAP.DOCUMENTATION' | translate"></div>
        <div class="documentation" [innerHTML]="'MAP.MONITORED_AREA_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['base'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Área monitorada'})">
        </div>
      }
      @if (show == 'reports') {
        <div class="documentation" [innerHTML]="'MAP.DOCUMENTATION' | translate"></div>
        <div class="documentation" [innerHTML]="'SAT.REPORT_IMAGE_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['reports'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Imagens sat em relatórios'})">
        </div>
        <div class="documentation" [innerHTML]="'REP.DRAW_SCAR_POLYGON_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['reports'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Desenho da cicatriz'})">
        </div>
      }
      @if (show == 'sat') {
        <div class="documentation" [innerHTML]="'MAP.DOCUMENTATION' | translate"></div>
        <div class="documentation" [innerHTML]="'SAT.TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['sat'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Detecção satelital'})">
        </div>
        <div class="documentation" [innerHTML]="'SAT.FAQ' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['sat'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'FAQ'})">
        </div>
      }
      @if (show == 'risk') {
        <div class="documentation" [innerHTML]="'MAP.DOCUMENTATION' | translate"></div>
        <div class="documentation" [innerHTML]="'MAP.RISK_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['risk'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Risco de incêndio'})">
        </div>
        <div class="documentation" [innerHTML]="'MAP.RISK_INFO_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['risk'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Informativo de risco'})">
        </div>
        <div class="documentation" [innerHTML]="'MAP.FUEL_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['risk'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Material combustível'})">
        </div>
        <div class="documentation" [innerHTML]="'MAP.WIND_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['risk'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Origem do vento'})">
        </div>
      }
      @if (show == 'fm') {
        <div class="documentation" [innerHTML]="'MAP.DOCUMENTATION' | translate"></div>
        <div class="documentation" [innerHTML]="'RESOURCES.TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['fm'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Brigadas e Pontos de interesse'})">
        </div>
        <div class="documentation" [innerHTML]="'RESOURCES.PANTERA_MOBILE_FLEET_TRACKING_TUTORIAL' | translate" 
          (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['fm'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Rastreamento com Pantera mobile'})">
        </div>
      }
    </section>
  }
</main>


