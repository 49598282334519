import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { GeoService } from '../../services/geo.service';

interface PoiType {
  legend: string,
  id_tipo_poi: number,
  legend2: string
}

@Component({
  selector: 'app-create-poi',
  templateUrl: './create-poi.component.html',
  styleUrls: ['./create-poi.component.scss']
})
export class CreatePoiComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  private newPoi: {};
  public types: PoiType[] = [];
  public selectedType: string;
  public poiTypeName: string;
  public selectedTypeId: number;
  public typeFc = new UntypedFormControl('', [Validators.required]);
  public poiName: string;
  public poiNameFc = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(String.raw`^(?!\s*$).+`)
  ]);
  public lat: number;
  public lon: number;
  public obs: string;
  public obsFc = new UntypedFormControl('', []);

  constructor(
    private user: UserDataService,
    private http: HttpService,
    private dialog: MatDialog,
    private amplitude: AmplitudeService,
    private translate: TranslateService,
    public geo:GeoService,
  ) { }

  ngOnInit(): void {
    this.getTypes();
    this.setupMapClickListener();
  }

  ngOnDestroy(): void {
    window.removeEventListener('mapClick', this.mapClickListener);
  }

  setupMapClickListener() {
    window.addEventListener('mapClick', this.mapClickListener);
  }

  mapClickListener = (event: any) => {
    const customEvent = event as CustomEvent;
    this.lat = customEvent.detail.lat.toFixed(5);
    this.lon = customEvent.detail.lng.toFixed(5);
  }
  
  async getTypes(){
    this.types = await this.http.maestroGet('get_tipo_poi');
    this.types.forEach(poi_type => {
      const poiTypeNameFront = this.translate.instant(`POI.${poi_type.id_tipo_poi}`) || 'update_dict';
      poi_type['legend2'] = poiTypeNameFront;
    });
    this.types.sort((a, b) => a.legend2.localeCompare(b.legend2));
  }

  async postPoi(){
    this.poiName = this.poiName.replace(/\s+/g, ' ').trim()
    for (let tipoPoi of this.types){
      if (this.selectedType === tipoPoi['legend2']){
        this.selectedTypeId = tipoPoi['id_tipo_poi'];
        this.poiTypeName = tipoPoi['legend'] // nome tipo POI p/ Amplitude
      }
    }
    this.newPoi = {
      'id_planta': this.user.getIdPlanta(),
      'nome_poi': this.poiName,
      'lat': this.lat,
      'lon': this.lon,
      'id_tipo_poi': this.selectedTypeId
    };
    if (this.obs){
      this.newPoi['obs'] = this.obs;
    } else {
      this.newPoi['obs'] = '';
    }
    for (let fc of [this.typeFc, this.poiNameFc, this.obsFc]) { fc.reset() };
    this.lat = null;
    this.lon = null;

    let response = await this.http.maestroPost('post_poi', this.newPoi);
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.POINT_OF_INTEREST_REGISTERED_DIALOG")}`}
      });
      this.amplitude.sendEvent('Cadastrou Recurso', {"Recurso": this.amplitude.resourceName['poi'], "Tipo": this.poiTypeName, "Nome": this.newPoi['nome_poi']});
      this.notifyParent.emit(this.newPoi)
      
      await this.geo.updatePoiGroup();
      this.geo.activateLayer(11, `POI.${this.selectedTypeId}`);
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_REGISTERING_POINT_OF_INTEREST_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }

  }

}
