import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";

import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { GeoService } from '../../services/geo.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hotspots',
  templateUrl: './hotspots.component.html',
  styleUrls: ['./hotspots.component.scss']
})
export class HotspotsComponent implements  OnInit {

  constructor(
    private dialog: MatDialog,
    private http: HttpService,
    public user: UserDataService,
    private amplitude: AmplitudeService,
    public sat: SatelliteService,
    public geo:GeoService,
    private translate: TranslateService,
  ) { }

  public hotspotTalhaoDfColumns: string[] = [
    'satellite',
    'acquisition_utc_offset',
    'detection_utc_offset',
    'talhao_nome',
    'alerted'
  ]
  
  async ngOnInit(){
  }

  async onEndDateChange(endDate: Date) {
    if (endDate) {
      await this.geo.updateSatDetectionHistoryGroup();
      this.amplitude.sendDatePickerEvent('sat', 'SAT.DETECTION_HISTORY_TAB', `[${formatDate(this.sat.startDate, 'dd-MMM-yyyy', this.sat.locale)}, ${formatDate(this.sat.endDate, 'dd-MMM-yyyy', this.sat.locale)}]`);
    }
  }

  async downloadXls(){
    if (!this.sat.startDate || !this.sat.endDate) return;
    
    let startDate = formatDate(this.sat.startDate, 'yyyy-MM-dd', this.sat.locale)
    let endDate = formatDate(this.sat.endDate, 'yyyy-MM-dd', this.sat.locale)

    if (!this.sat.hotspotTalhao.length){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("SAT.NO_DETECTION_HISTORY_DATA")}`}
      });
    } else {
      let response = await this.http.maestroGetBinary(`download_hotspot_talhao_xlsx/${this.user.getIdPlanta()}/${startDate}/${endDate}`);
      
      let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let blobUrl = window.URL.createObjectURL(blob);
      
      let link = document.createElement('a');
      link.href = blobUrl;
      link.download = `pantera_focos_calor_${startDate}_${endDate}.xlsx`; //[TO DO] O nome do arquivo já é retornado pela rota; mas não consegui acessá-lo ainda por meio da variável "response"
      //Simulate click on BLOB hyperlink
      link.click();

      this.amplitude.sendEvent('Baixou Detecção Satelital');
    }
  }

  selectHotspotTalhao(id_hotspot){
    this.geo.activateLayer(10, 'SAT.HOTSPOT_DETECTION_HISTORY_LAYER');
    this.geo.openDetectionHistoryPopup(id_hotspot);
    this.sat.selectedHotspotTalhao = id_hotspot;
    console.log('selectedHotspotTalhao', this.sat.selectedHotspotTalhao)
  }

  get currentLocale(): string {
    return this.translate.currentLang === 'pt-br' ? 'pt-BR' : 'en-US';
  }
}