import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { UntypedFormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GeoService } from '../../services/geo.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { GeoLayersService } from '../../services/geo.layers';
import { MeteoService } from 'src/app/services/meteo.service';

const icon_dir = './assets/icons/'

interface ViewOption {
  id: number;
  option: string;
  icon: string;
}
@Component({
  selector: 'app-meteo-stations',
  templateUrl: './meteo-stations.component.html',
  styleUrl: './meteo-stations.component.scss'
})
export class MeteoStationsComponent implements OnInit {
  public stationsViewFormControl = new UntypedFormControl('');
  public stationsViewOptions: ViewOption[] = [
    { id: 1, option: "MAP.LIST_METEO_STATIONS", icon: 'list' },
    { id: 2, option: "MAP.CREATE_STATION", icon: 'add_location_alt' },
  ]
  public selectedStationViewOption: number = this.stationsViewOptions[0].id;

  constructor(
    private http: HttpService,
    public user: UserDataService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public geo:GeoService,
    private amplitude: AmplitudeService,
    public layer: GeoLayersService,
    public meteo: MeteoService
  ) { }

  public STATION_COLUMNS_SCHEMA = [
    { key: "station_name", label: `${this.translate.instant("MAP.NAME")}` },
    { key: "lat_station", label:"Latitude" },
    { key: "lon_station", label:"Longitude" },
    { key: "acao", label:"" }
  ]

  public loaded:boolean = false;
 
  public stations;
  public stationColumns: string[] = this.STATION_COLUMNS_SCHEMA.map((col) => col.key);
  stationColumnsSchema = this.STATION_COLUMNS_SCHEMA;
  public searchField: string;

  ngOnInit(){
    this.getMeteoStations();
    this.loaded = true;
  }

  async getMeteoStations(){
    this.stations = []
    
    let stations_raw = await this.user.getDadosPantera('stations');
    if (!!stations_raw.features){
      for (let station of stations_raw.features){
        

        this.stations.push({
          lat_station: station.geometry.coordinates[1],
          lon_station: station.geometry.coordinates[0],
          station_name: station.properties.station_name,
          station_id: station.properties.station_id,
        })
      }
    }
    if (this.stations.length){
      for (let station of this.stations){
        station['lat_station'] = (station['lat_station']*100/100).toFixed(4);
        station['lon_station'] = (station['lon_station']*100/100).toFixed(4);
      }
      this.stations.sort((a,b) => this.sortAlph(a['station_name'], b['station_name']));
    }
  }

  async removeMeteoStation(station){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_METEO_STATION_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (!confirmation) return
      this.stations = this.stations.filter(p => p != station)
      if (station.station_id){
        let response = await this.http.maestroPost('remove_meteo_station', station)
        this.openResponseDialog(response, station)
        await this.user.updatePanteraData();
        await this.geo.updateMeteoStationGroup();
        this.meteo.getValidMeteo();
      }
    })
  }

  openResponseDialog(response, station){
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.METEO_STATIONS_UPDATED_DIALOG")}`}
      });
      this.amplitude.sendEvent('Removeu Estação Meteo', { "Nome": station['station_name'], "ID": station.station_id});
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_UPDATING_STATIONS_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }
  }

  async onChildEvent(station){
    this.stations.push({
      lat_station: station.lat,
      lon_station: station.lon,
      station_name: station.station_name,
    })
    await this.user.updatePanteraData()
    this.getMeteoStations()
  }

  sortAlph(a:string,b:string){
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  sortData(sort: Sort) {
    const data = this.stations.slice();

    if (!sort.active || sort.direction === '') {
      this.stations = data;
    } else {
      this.stations = data.sort((a, b) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  selectMeteoStation(station_id: string) {
    this.geo.activateLayer(82, 'MAP.VIRTUAL_STATIONS');
    this.geo.openMeteoStationPopup(station_id); 
    this.layer.selectedMeteoStation = station_id;
    let stationName = this.stations.find(st => st.station_id === station_id)?.station_name;
    this.amplitude.sendEvent("Clicou Estação Meteorológica", { "Nome": stationName });
  }
  

  amplitudeSubTabEvent(selectedSubTab){
    this.amplitude.sendSubTabViewEvent('weather', 'MAP.VIRTUAL_STATIONS', this.stationsViewOptions, selectedSubTab, 'option')
  }
}
