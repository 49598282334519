
@if (rep.imagesLoaded() && rep.imgs[rep.selectedReportImageIndex()]) {
  <main>
    <div class="img-wrapper">
      <div class="img-index-container">
        {{1+rep.selectedReportImageIndex()}}/{{rep.imgs.length}}
      </div>
      <div class="metadata-image">
        @if (rep.imgs[rep.selectedReportImageIndex()].img_timestamp) {
          <span class="metadata">
            {{rep.imgs[rep.selectedReportImageIndex()].img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
          </span>
        }
        @if (rep.imgs[rep.selectedReportImageIndex()].img_details.pan != null) {
          <span class="metadata">
            {{rep.imgs[rep.selectedReportImageIndex()].img_details.pan}}° | {{rep.imgs[rep.selectedReportImageIndex()].img_details.zoom}}x
            @if (rep.imgs[rep.selectedReportImageIndex()].img_details?.dist){
              <span> | {{rep.imgs[rep.selectedReportImageIndex()].img_details.dist | number: '1.2-2'}}km - </span>
            }
            @if (rep.imgs[rep.selectedReportImageIndex()].img_details?.nome){
              <span>{{rep.imgs[rep.selectedReportImageIndex()].img_details.nome}}</span>
            }
            @if (user.isDev()) {
              <span> [ {{rep.imgs[rep.selectedReportImageIndex()].img_type}} ]</span>
            }
          </span>
        }
      </div>
      <canvas id='detectionCanvas'></canvas>
      <!-- imagem s3 -->
      @if (rep.imgs[reports.selectedReportImageIndex()].img_url && !b64) {
        <img [src]='rep.imgs[reports.selectedReportImageIndex()].img_url' class='fire-image' (load)='drawRect()'/>
      }
      <!-- imagem b64 -->
      @if (b64) {
        <img [src]="rep.imgs[reports.selectedReportImageIndex()].base64 | safe: 'resourceUrl'" class='fire-image' (load)='drawRect()'>
      }
      @if (!rep.imgs[reports.selectedReportImageIndex()].img_url && !b64) {
        <img src="https://i.stack.imgur.com/y9DpT.jpg" class='fire-image'/>
      }
      @if (!b64 && rep.imgs.length > 1) {
        <button
          id='previousImage'
          mat-mini-fab
          (click)="previousImage()">
          <mat-icon [color]="'accent'">navigate_before</mat-icon>
        </button>
      }
      @if (!b64 && rep.imgs.length > 1) {
        <button
          id='nextImage'
          mat-mini-fab
          (click)="nextImage()">
          <mat-icon [color]="'accent'">navigate_next</mat-icon>
        </button>
      }
    </div>
  </main>
}