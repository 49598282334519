import { Component, OnInit } from '@angular/core';
import { SocketService } from 'src/app/services/socket.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';
import {HttpService} from 'src/app/services/http.service';
import { UserDataService} from 'src/app/services/user-data.service';

@Component({
  selector: 'app-deploy-v2',
  templateUrl: './deploy-v2.component.html',
  styleUrl: './deploy-v2.component.scss'
})

export class DeployV2Component implements OnInit{
  constructor(
    private socket: SocketService,
    private dialog: MatDialog,
    private http: HttpService,
    private user: UserDataService
  ) { }

  private dialogRef: MatDialogRef<any> | null = null;
  public endpointList: any;
  public loaded = false;

  async ngOnInit(){
    this.subscribeToAttDadosRemotoFeedback();
    await this.getPortainerEndpoints();
    this.loaded = true;
  }

  atualizarDadosRemoto(){
    this.socket.sendAtualizarDadosRemoto();
  }

  subscribeToAttDadosRemotoFeedback(){
    this.socket.onFeedBackAttDadosEvent().subscribe(async (msg) => {
      const numClients = msg['numClientes'] - 1;
      const clientText = numClients === 1 ? 'computador respondeu' : 'computadores responderam';
      
      if (!this.dialogRef){
        this.dialogRef = this.dialog.open(InfoDialog, {
          data: {text: `Dados atualizados no cliente com sucesso! ${numClients} ${clientText}`},
        });
      }
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    });
  }

  async getPortainerEndpoints(){
    this.endpointList = await this.http.maestroGet(`get_portainer_data/${this.user.getIdPlanta()}`);
    this.endpointList.sort((a, b) => b.stacks.length - a.stacks.length);
  }

}
