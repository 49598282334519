<main>
  <nav class='lang-select-nav'>
    <div>
      <span>
        <select
          #selectedLang
          (change)="switchLang(selectedLang.value); user.saveLang(selectedLang.value)"
          >
          <option [value]="'pt-br'" [selected]="translate.currentLang=='pt-br'">Português</option>
          <option [value]="'en'" [selected]="translate.currentLang=='en'">English</option>
        </select>
      </span>
    </div>
  </nav>

  <div class="logo-container">
    <div class="full-negative-logo"></div>
  </div>

  <div class="login-container">
    @if (showErrorMessage) {
      <h3 style='color:red; display: flex;'>
        <mat-icon>error_outline</mat-icon>
        {{ 'MAP.INVALID_CREDENTIALS' | translate }}
      </h3>
    }
    @if (loginService.wrongMfa()) {
      <h3 style='color:red; display: flex;'>
        <mat-icon>error_outline</mat-icon>
        {{ 'MAP.WRONG_MFA' | translate }}
      </h3>
    }
    @if(loginService.mfaExpired()) {
      <h3 style='color:red; display: flex;'>
        <mat-icon>error_outline</mat-icon>
        {{ 'MAP.EXPIRED_MFA' | translate }}
      </h3>
    }
    @if (!isMfaRequired) {
      <ng-container>
        <mat-form-field appearance="outline" color='primary'>
          <mat-label>{{ 'MAP.USERNAME' | translate }}</mat-label>
          <span matPrefix><mat-icon>account_circle</mat-icon></span>
          <input matInput [(ngModel)]="username" (change)='showErrorMessage=false'>
        </mat-form-field>
    
        <mat-form-field appearance="outline" color='primary'>
          <mat-label>{{ 'MAP.PASSWORD' | translate }}</mat-label>
          <span matPrefix><mat-icon>vpn_key</mat-icon></span>
          <input matInput [type]="showPass ? 'text' : 'password'" [(ngModel)]="userpass" (keyup.enter)="sign_in()">
          <button mat-icon-button matSuffix (click)="showPass = !showPass">
            <mat-icon>{{showPass ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>
    
        <div class='check-box-container'>
          <mat-checkbox color="warn" [(ngModel)]='rememberMe'>
            {{ 'MAP.REMEMBER_ME' | translate }}
          </mat-checkbox>
        </div>
    
        <p class='forgot-pass' (click)='forgot_pass()'>
          {{ 'MAP.FORGOT_PASSWORD' | translate }}
          <mat-icon [inline]="true">help_outline</mat-icon>
        </p>
    
        <!-- <p class='forgot-pass' (click)='login_sso()'>
          {{ 'MAP.SIGN_IN_SSO' | translate }}
        </p> -->
    
        <button mat-raised-button class="login-btn" color='warn' (click)='sign_in()'>
          {{ 'MAP.SIGN_IN' | translate }}
        </button>
      </ng-container>
    } @else {
      <ng-container>
        <p>{{ 'MAP.ENTER_MFA_CODE' | translate }}</p>
        <p>{{ 'MAP.MFA_CODE_SENT' | translate }} {{ hiddenEmail }}</p>
       
        <mat-form-field appearance="outline" color='primary'>
          <mat-label>{{ 'MAP.MFA_CODE' | translate }}</mat-label>
          <span matPrefix><mat-icon>security</mat-icon></span>
          <input matInput [(ngModel)]="mfaCode" maxlength="6" (input)="mfaCode = mfaCode?.slice(0, 6)" (keydown.space)="$event.preventDefault()" (keyup.enter)="submitMfaCode()">
        </mat-form-field>

        @if( countDownCheck()){
        <p>{{ 'MAP.NEW_MFA' | translate }} {{ countdown() }}</p>
        }@else {
        <p (click)="resendMfaCode()" color='warn' class ="new-code">{{ 'MAP.REQUEST_NEW_CODE' | translate }}</p>
        }

        @if(!loginService.mfaExpired()){
        <button [disabled]="mfaCode?.length < 6" mat-raised-button class="login-btn" color='warn' (click)='submitMfaCode()'>
          {{ 'MAP.SIGN_IN' | translate }}
        </button>
      }@else {
        <button mat-raised-button class="login-btn" color='warn' (click)='sign_in()'>
          {{ 'MAP.RESEND_CODE' | translate }}
        </button>
      }
      </ng-container>
    }
  </div>

  <div class="version-container">
    {{ 'MAP.VERSION' | translate }} {{version}}
  </div>
  <span class='ugm'>
    umgrauemeio®2025
  </span>
</main>
